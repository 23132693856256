import { combineReducers } from 'redux';

import userReducer from './userData/reducer';
import campaignSetupReducer from './campaignSetup/reducer';
import guestUserReducer from './guestUserData/reducer';
import voucherReducer from './voucher/reducer';

const reducers = combineReducers({
    guestUserReducer: guestUserReducer,
    userReducer: userReducer,
    campaignSetupReducer: campaignSetupReducer,
    voucherReducer: voucherReducer,
});

export default reducers;
