import React, { useState } from 'react';
import {
    Box,
    Card,
    CardActions,
    CardActionArea,
    Dialog,
    Grid,
    Stack,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material/';
import { Instagram } from '@mui/icons-material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useDispatch } from 'react-redux';

import Button from '../Button';
import { averageLikePerPostFormat } from '../../utils/formatters';
import CardItemMenuDropdown from '../CardItemMenuDropdown';
import Notification from '../Notification';
import ReportDialog from './ReportDialog';
import { postCampaignRecommendation } from '../../services/apiRecommendation';
import { setCampaignNameList } from '../../redux/userData/actions';

const CardItem = ({
    creatorItem,
    campaigns,
    updatePurchasedCreators = () => {},
    isThisCreatorPurchased = false,
    setIsThisCreatorPurchased = () => {},
    isThisADemo = false,
}) => {
    const dispatch = useDispatch();

    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [CreateNewCampaignDialogOpen, setCreateNewCampaignDialogOpen] = useState(false);

    const [showAddedCreatornotification, setShowAddedCreatorNotification] = useState(false);
    const [notificationType, setNotificationType] = useState(null);

    const notificationMessages = {
        success: `${creatorItem.instagramUsername} added to your campaign list`,
        exists: `${creatorItem.instagramUsername} is already in your campaign list`,
        created: `${creatorItem.instagramUsername} added to your new campaign list`,
        error: 'An error occurred. List may have been removed. Please refresh the page.',
    };

    const updateCampaignsListNames = (campaignNames) => {
        dispatch(setCampaignNameList(campaignNames));
    };

    const handleAddNewListMenuClick = () => {
        setCreateNewCampaignDialogOpen(true);
    };

    const handleSubmitNewCampaignName = async (listName) => {
        const response = await postCampaignRecommendation({
            title: listName,
            likedCreators: [creatorItem._id],
        });
        if (response.status === 201) {
            setNotificationType('created');
            setShowAddedCreatorNotification(true);
        }
        const { allCampaigns } = response.data;
        updateCampaignsListNames(allCampaigns);
    };

    const handlePaymentDialogOpenClose = () => setPaymentDialogOpen(!paymentDialogOpen);

    const formatNumbersToKiloDotDecimal = (number) => {
        let formattedNumber = parseInt(number);

        let postfixValue;
        let postfixedText;

        if (formattedNumber < 1000000) {
            postfixValue = Math.round(formattedNumber / 100) / 10;
            postfixedText = `${postfixValue} K`;
        } else {
            postfixValue = Math.round(formattedNumber / 100000) / 10;
            postfixedText = `${postfixValue} M`;
        }

        return postfixedText;
    };
    return (
        <Grid item xs={12} sm={6} md={6} lg={5}>
            <Card
                sx={{
                    position: 'relative',
                    borderRadius: '1vw',
                    padding: '0.5vw',
                    paddingBottom: '0.75vw',
                    '&:hover': {
                        boxShadow: '1vw 1vh 3rem #CCCCCC',
                        transform: 'scale(1.01)',
                    },
                    backgroundColor: (theme) => theme.palette.background.main,
                }}
            >
                <CardActions
                    style={{
                        width: '100%',
                        p: '0',
                        m: '0',
                        position: 'absolute',
                        zIndex: '5',
                    }}
                >
                    <Box
                        sx={{
                            width: '50px',
                            height: '50px',
                            backgroundColor: '#fff',
                            position: 'absolute',
                            left: '0px',
                            top: '-7px',
                            borderRadius: '10px 0px 25px 0px',
                        }}
                    >
                        <CardItemMenuDropdown
                            campaigns={campaigns}
                            creatorId={creatorItem._id}
                            setShowAddedCreatorNotification={setShowAddedCreatorNotification}
                            setNotificationType={setNotificationType}
                            handleAddNewListMenuClick={handleAddNewListMenuClick}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '130px',
                            height: '50px',
                            backgroundColor: '#fff',
                            position: 'absolute',
                            right: '0px',
                            top: '-7px',
                            borderRadius: '0px 10px 0px 25px',
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handlePaymentDialogOpenClose}
                            sx={{
                                position: 'absolute',
                                top: '8px',
                                left: '-20px',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                color: 'loveSelection.void',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    color: 'loveSelection.void',
                                },
                                '&:visited': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    color: 'loveSelection.void',
                                },
                            }}
                        >
                            See report
                        </Button>
                    </Box>
                </CardActions>

                <CardActionArea
                    sx={{
                        '&:before': {
                            content: "''",
                            display: 'block',
                            paddingTop: '100%' /* initial ratio of 1:1*/,
                        },
                        backgroundImage: `url(${creatorItem.profileImage}), url('https://images-vea4cdfsm.s3.eu-central-1.amazonaws.com/avatar.svg')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '1vw',
                        marginBottom: '1.1vh',
                    }}
                    onClick={handlePaymentDialogOpenClose}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '13%',
                            padding: '0.5vw',
                            bottom: 0,
                            left: 0,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '0 0 1vw 1vw',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            color: 'white',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                color: 'white',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                textAlign: 'center',
                                padding: '0 5px',
                            }}
                        >
                            {creatorItem.instagramUsername}
                        </Typography>
                    </Box>
                </CardActionArea>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        spacing: 0,
                    }}
                    style={{ padding: '5px 14px 5px 5px' }}
                >
                    {/* see report button */}
                    <Stack direction="column" width="53%" spacing={0}>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                        >
                            {!isThisADemo || isThisCreatorPurchased ? (
                                <Button
                                    onClick={() =>
                                        window.open(
                                            `http://instagram.com/${creatorItem.instagramUsername}`,
                                            '_blank'
                                        )
                                    }
                                    startIcon={<Instagram />}
                                    size="small"
                                    sx={{
                                        color: 'white',
                                        backgroundColor: '#8E24AA',
                                        '&:hover': {
                                            backgroundColor: '#5E35B1',
                                        },
                                    }}
                                >
                                    Visit Profile
                                </Button>
                            ) : (
                                <Typography variant="h7" width={'140px'}>
                                    {creatorItem.name.slice(0, 12) < creatorItem.name
                                        ? `${creatorItem.name.slice(0, 12)}...`
                                        : creatorItem.name}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>

                    {/* creator metrics */}
                    <Stack direction="column" width="47%" alignItems={'flex-end'} spacing={0}>
                        <Box>
                            <Tooltip title={'followers count'} enterDelay={600}>
                                <Stack direction="row" spacing={0.2} alignItems="center">
                                    <PeopleAltIcon />
                                    <Typography fontSize="0.8rem">
                                        {formatNumbersToKiloDotDecimal(creatorItem.followersCount)}
                                    </Typography>
                                </Stack>
                            </Tooltip>
                            <Tooltip title={'average likes of last post'} enterDelay={600}>
                                <Stack direction="row" alignItems="end">
                                    <Typography fontSize="0.8rem">
                                        {` ${averageLikePerPostFormat(
                                            creatorItem.averageLikesOfLastPosts
                                        )}`}
                                        &nbsp;/ post
                                    </Typography>
                                </Stack>
                            </Tooltip>
                        </Box>
                    </Stack>
                </Stack>
            </Card>

            {/* Dialogs and Notifications */}
            <CreateNewCampaignDialog
                open={CreateNewCampaignDialogOpen}
                handleCloseDialog={() => {
                    setCreateNewCampaignDialogOpen(false);
                }}
                handleSubmit={handleSubmitNewCampaignName}
            />
            <ReportDialog
                open={paymentDialogOpen}
                handleDialogOpenClose={handlePaymentDialogOpenClose}
                creatorItem={creatorItem}
                campaigns={campaigns}
            />

            <Notification
                message={notificationMessages[notificationType]}
                open={showAddedCreatornotification}
                onClose={() => {
                    setShowAddedCreatorNotification(false);
                }}
                type={notificationType}
            />
        </Grid>
    );
};

const CreateNewCampaignDialog = ({ open, handleCloseDialog, handleSubmit }) => {
    const [listName, setListName] = useState('');
    const [isCampaignNameInvalid, setIsCampaignNameInvalid] = useState(false);

    const handleListNameChange = (event) => {
        const newName = event.target.value;
        setListName(newName);
        setIsCampaignNameInvalid(newName.length < 3);
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Paper
                sx={{
                    padding: '40px',
                    width: '400px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h6" paddingBottom={2} style={{ textAlign: 'center' }}>
                    Create new Campaign List
                </Typography>
                <TextField
                    required
                    fullWidth
                    name="listName"
                    value={listName}
                    label="List Name"
                    onChange={handleListNameChange}
                    helperText={isCampaignNameInvalid ? 'Enter a valid name for the new list.' : ''}
                    error={isCampaignNameInvalid}
                />
                <Button
                    variant="contained"
                    onClick={() => {
                        if (listName.length < 3) {
                            setIsCampaignNameInvalid(true);
                        } else {
                            handleSubmit(listName);
                            handleCloseDialog();
                        }
                    }}
                    sx={{
                        marginTop: '20px',
                    }}
                    disabled={isCampaignNameInvalid}
                >
                    Create
                </Button>
            </Paper>
        </Dialog>
    );
};

export default CardItem;
