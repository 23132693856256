import { ReactComponent as LogoSVG } from '../../images/wcxbcl.svg';
import { useNavigate, Link as LinkRRD } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material/';

export const LogoMark = ({ sx }) => {
    return (
        <Box
            sx={{
                width: '40px',
                height: '40px',
                marginRight: '16px',
                marginLeft: '0px',
                justifyContent: 'center',
                display: { xs: 'flex', md: 'flex' },
                ...sx,
            }}
        >
            <LogoSVG />
        </Box>
    );
};

export const LogoType = () => {
    return (
        <Typography
            variant="h6"
            noWrap
            sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'rubik',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
            }}
        >
            WINECONX
        </Typography>
    );
};

export const Logo = ({ isLogoHomeButton }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (isLogoHomeButton) {
            navigate('/home');
        }
    };

    const LogoContent = (
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
            <LogoMark />
            <LogoType />
        </Box>
    );

    return isLogoHomeButton ? (
        <Button
            onClick={handleClick}
            sx={{
                padding: 0,
                minWidth: 'auto',
                color: 'inherit',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:active': {
                    backgroundColor: 'transparent',
                },
                textTransform: 'none', // To prevent uppercasing of text
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {LogoContent}
        </Button>
    ) : (
        LogoContent
    );
};
/* 
    <Box
            id="mobileNavBarBox"
            component={Link}
            to={isLoggedIn ? '/description' : '/home'}
            paddingTop="10px"
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            sx={{
                position: 'fixed',
                top: '0',
                width: '100vw',
                paddingLeft: '16px',
                zIndex: '1350',
                backgroundColor: 'white',
            }}
            >
            <Logo width="150px" height="35px" />
</Box> */
