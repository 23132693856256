import React, { useState, Fragment, useEffect } from 'react';
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material/';
import { Delete } from '@mui/icons-material';

import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import CreatorRow from './CreatorRow';

const CampaignRow = ({ campaign, openDefault, removeCreatorFromCampaign, removeList }) => {
    const [open, setOpen] = useState(openDefault);
    const [openRemoveListAlertDialog, setOpenRemoveListAlertDialog] = useState(false);

    useEffect(() => {
        const createdAt = new Date(campaign.createdAt);
        const now = new Date();
        const diff = now.getTime() - createdAt.getTime();

        if (diff < 5000) {
            setOpen(true);
        }
    }, []);

    return (
        <Fragment>
            <TableRow
                sx={{
                    width: '100%',
                    '& > *': { borderBottom: 'unset' },
                }}
            >
                <TableCell
                    sx={{
                        width: '100%',
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Stack direction="row" alignItems="center">
                            <IconButton
                                edge="start"
                                aria-label="expand row"
                                size="medium"
                                onClick={() => setOpen(!open)}
                                sx={{
                                    height: '40px',
                                    width: '40px',
                                    padding: '0px',
                                }}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            <Typography sx={{ fontSize: '20px' }}>{campaign.title}</Typography>
                        </Stack>

                        <IconButton
                            onClick={() => {
                                setOpenRemoveListAlertDialog(true);
                            }}
                            sx={{
                                display: open ? 'block' : 'none',
                                '&:hover': {
                                    color: 'red',
                                },
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Stack>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    sx={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                    colSpan={6}
                >
                    <Box
                        sx={{
                            zIndex: '20001',
                            boxShadow: '0.5vw 0.5vh 5rem #EEE',
                            margin: '20px',
                            borderRadius: '20px',
                        }}
                    >
                        <Collapse in={open}>
                            <Table
                                size="large"
                                aria-label="campaignTimeline"
                                sx={{
                                    '& .MuiTableCell-root': {
                                        borderBottom: '2px dashed #f5f4f7',
                                    },
                                    '& tr:last-child td': {
                                        borderBottom: 'none',
                                    },
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell
                                            sx={{
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            <Typography fontSize={14} color="#909090">
                                                Name
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                fontSize={14}
                                                color="#909090"
                                                paddingLeft="25px"
                                            >
                                                Instagram ID
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            <Typography fontSize={14} color="#909090">
                                                Based in
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                fontSize={14}
                                                color="#909090"
                                                paddingLeft="25px"
                                            >
                                                comment / post
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography fontSize={14} color="#909090">
                                                Likes / post
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography fontSize={14} color="#909090">
                                                Remove
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaign.likedCreators.map((creatorItem, ci) => {
                                        return (
                                            <CreatorRow
                                                key={`campaign_${campaign.title}_creator_${ci}`}
                                                campaignId={campaign._id}
                                                creator={creatorItem}
                                                onDelete={removeCreatorFromCampaign}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </Box>
                </TableCell>
            </TableRow>
            <RemoveListDialog
                open={openRemoveListAlertDialog}
                setOpen={setOpenRemoveListAlertDialog}
                listId={campaign._id}
                removeList={removeList}
            />
        </Fragment>
    );
};

const RemoveListDialog = ({ open, setOpen, listId, removeList }) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Remove List'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove this creator list?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        removeList(listId);
                        setOpen(false);
                    }}
                    color="primary"
                    autoFocus
                    variant="contained"
                >
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CampaignRow;
