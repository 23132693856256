const initialState = {
    tasks: '1 post',
    compensation: '1 bottle of wine',
    campaign: {
        ownerId: '',
        status: 'DRAFT',
        title: '',
        offerDescription: '',
        campaignObject: '',
        productPriceRange: '',
        productType: '',
        typeOfContent: '',
        socialMediaPlatforms: '',
        ageRangeOfTargetGroup: '',
        regionOfTargetGroup: '',
        wishes: '',
        notAllowed: '',
        startingDate: '',
        endingDate: '',
        campaignImageURL: '',
        missions: [],
        files: [],
        brandName: '',
        productName: '',
        retailPrice: '',
        foodieLabelSelector: false,
        fineWineLabelSelector: false,
        wineLoverLabelSelector: false,
        wineExpertLabelSelector: true,
        lifestyleLabelSelector: false,
        wineRegionLabelSelector: '',
        wineProducingRegions: '',
        sommelierLabelSelector: '',
        creatorTypeBasedOnFollowers: [],
        typeOfWine: null,
        campaignRegion: '',
        targetAudienceLocation: '',
        ignoreDisliked: true,
        ignoreLiked: false,

        reactionsToRecommendedCreators: [],
    },
    campaignMissingFields: [],
};

export default initialState;
