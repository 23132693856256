import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Box, TextField, Typography, Stack } from '@mui/material/';

import {
    userBrandRegistration,
    checkEmailAvailability,
    checkUsernameAvailability,
    registerNewUserInFirebase,
    sendEmailVerificationFirebase,
    logOutFirebase,
} from '../../services';
import Button from '../../components/Button';

import AppFrame from '../../hoc/AppFrame';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';
import PageMetaData from '../../components/PageMetaData/';

const Signup = () => {
    useAuthorization([roles.PUBLIC]);
    const navigate = useNavigate();

    const validationSchema = yup.object({
        brandName: yup.string('Enter the name of the Brand').required('Company name is required'),

        website: yup
            .string('Enter website')
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter a correct url'
            ),
        username: yup
            .string('Enter your name')
            .min(3, 'The name should be of minimum 3 characters length')
            .required('Name is required')
            .test('Unique Username', 'Username already in use', async function (username) {
                const { data } = await checkUsernameAvailability(username);
                return data.isUsernameAvailable;
            }),
        phone: yup
            .string('Enter your phone number')
            .min(10, 'The phone number should be of minimum 10 characters length'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
            .test('Unique Email', 'Email already in use', async function (email) {
                const { data } = await checkEmailAvailability(email);
                return data.isEmailAvailable;
            }),

        password: yup
            .string('Enter your password')
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            brandName: '',
            website: '',
            username: '',
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const firebaseResponse = await registerNewUserInFirebase(
                    values.email,
                    values.password
                );

                const response = await userBrandRegistration(values).catch((error) => {
                    console.log('Error at user registration');
                    console.log(error);
                    console.log(error.message);
                    // TODO: clean entry from firebase
                });
                if (response) {
                    sendEmailVerificationFirebase();
                    navigate('/registrationsuccess');
                }

                logOutFirebase();
            } catch (error) {
                console.log(error);
            }
        },
    });

    return (
        <>
            <PageMetaData
                title={'WineConX | Register'}
                description={
                    'Sign up and start influencer marketing with the best creator matches.'
                }
            />
            <Box
                sx={{
                    // marginTop: '25vh',
                    margin: '96px auto 0vh auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20%',
                    width: '450px',
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        // marginBottom: '0px',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        paddingBottom: '8px',
                    }}
                >
                    Register
                </Typography>
                <Typography
                    variant="subtitle1"
                    fontSize={14}
                    sx={{ textAlign: 'center', marginBottom: '8px' }}
                >
                    Sign up now and make your first searches for free!
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        sx={{ marginTop: '24px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="brandName"
                        name="brandName"
                        label="Company name"
                        value={formik.values.brandName}
                        onChange={formik.handleChange}
                        error={formik.touched.brandName && Boolean(formik.errors.brandName)}
                        helperText={formik.touched.brandName && formik.errors.brandName}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="website"
                        name="website"
                        label="Website"
                        value={formik.values.website}
                        onChange={formik.handleChange}
                        error={formik.touched.website && Boolean(formik.errors.website)}
                        helperText={formik.touched.website && formik.errors.website}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="username"
                        name="username"
                        label="Your name"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone number"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Button
                            sx={{
                                marginTop: '10px',
                                width: '100%',
                            }}
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        </>
    );
};

export default AppFrame(Signup);
