import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCampaignData } from '../../redux/campaignSetup/actions';

import {
    Button,
    Checkbox,
    Grid,
    Stack,
    Paper,
    TextField,
    Typography,
    Collapse,
} from '@mui/material/';

import CampaignRegionSelector from '../../components/DropdownSelector';
import TargetAudienceLocation from '../../components/DropdownSelector';
import CreatorTypeBasedOnFollowersSelector from '../../components/DropdownSelector';

import { countriesList } from '../../utils/countriesAndStatesList';

const MINIMUM_CREATORS_COUNT_BY_COUNTRY = 12;

const CampaignStepDescription = ({ nextButtonTouched, requiredFields }) => {
    const [moreOpen, setMoreOpen] = useState(false);

    const { campaign: campaignData } = useSelector((state) => state.campaignSetupReducer);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const newValues = { [event.target.name]: event.target.value };
        dispatch(setCampaignData(newValues));
    };

    const setSelectedValue = (fieldName, newValue) => {
        dispatch(setCampaignData({ [fieldName]: newValue }));
    };

    const setDislikeFilter = async (isChecked) => {
        setSelectedValue('ignoreDisliked', isChecked);
    };

    const setLikeFilter = async (isChecked) => {
        setSelectedValue('ignoreLiked', isChecked);
    };

    const textFieldStyles = {
        '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
            '&:hover fieldset': {
                borderColor: 'black',
            },
        },
    };

    const tableStyles = {
        paddingTop: '3vw',
        paddingBottom: '48px',
        paddingLeft: '3vw',
        paddingRight: '3vw',

        borderRadius: '30px',
    };

    return (
        <Stack paddingTop={10}>
            <Paper sx={tableStyles}>
                <Grid container columns={12} spacing={4}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            name="title"
                            value={campaignData.title}
                            label="Campaign title"
                            variant="outlined"
                            onChange={handleChange}
                            sx={textFieldStyles}
                            error={nextButtonTouched && !campaignData.title}
                            helperText={
                                nextButtonTouched && !campaignData.title && 'Title cannot be empty'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CreatorTypeBasedOnFollowersSelector
                            name="creatorTypeBasedOnFollowers"
                            fieldNameLabel="Creator amount of followers "
                            setChosenOptions={setSelectedValue}
                            defaultValue={campaignData.creatorTypeBasedOnFollowers}
                            nextButtonTouched={nextButtonTouched}
                            required={
                                !!requiredFields.find(
                                    (expectedKey) => expectedKey === 'creatorTypeBasedOnFollowers'
                                )
                            }
                            multiple={true}
                            optionsLabels={[
                                'Nano (1K to 10K)',
                                'Micro (10K to 80K)',
                                'Macro (more than 80K)',
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TargetAudienceLocation
                            name="targetAudienceLocation"
                            fieldNameLabel="Location of the target audience"
                            setChosenOptions={setSelectedValue}
                            defaultValue={campaignData.targetAudienceLocation}
                            nextButtonTouched={nextButtonTouched}
                            required={
                                !!requiredFields.find(
                                    (expectedKey) => expectedKey === 'targetAudienceLocation'
                                )
                            }
                            optionsLabels={countriesList.reduce(
                                (countriesToDisplay, countryItem) => {
                                    const modifiedCountriewsList = countriesToDisplay;
                                    if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                        modifiedCountriewsList.push(countryItem.country);
                                    }
                                    return modifiedCountriewsList;
                                },
                                []
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CampaignRegionSelector
                            name="campaignRegion"
                            fieldNameLabel="Creator is based in"
                            setChosenOptions={setSelectedValue}
                            defaultValue={campaignData.campaignRegion}
                            nextButtonTouched={nextButtonTouched}
                            required={
                                !!requiredFields.find(
                                    (expectedKey) => expectedKey === 'campaignRegion'
                                )
                            }
                            optionsLabels={countriesList.reduce(
                                (countriesToDisplay, countryItem) => {
                                    const modifiedCountriewsList = countriesToDisplay;
                                    if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                        modifiedCountriewsList.push(countryItem.country);
                                    }
                                    return modifiedCountriewsList;
                                },
                                []
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={6} md={6}>
                        <SommelierSelector
                            name="sommelierLabelSelector"
                            fieldNameLabel="Target Sommeliers?"
                            setChosenOptions={setSelectedValue}
                            defaultValue={campaignData.sommelierLabelSelector}
                            nextButtonTouched={nextButtonTouched}
                            required={
                                !!requiredFields.find(
                                    (expectedKey) =>
                                        expectedKey === 'sommelierLabelSelector'
                                )
                            }
                            multiple={false}
                            optionsLabels={['yes', 'no']}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Button
                            onClick={() => {
                                setMoreOpen(!moreOpen);
                            }}
                        >
                            more
                        </Button>
                        <Collapse in={moreOpen}>
                            <Paper
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    paddingLeft: '3vw',
                                    paddingRight: '3vw',
                                    borderRadius: '30px',
                                }}
                            >
                                <Stack
                                    direction="column"
                                    sx={{
                                        color: '#666666',
                                    }}
                                >
                                    <span>
                                        <Stack
                                            direction="row"
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Checkbox
                                                label="hideDisliked"
                                                checked={campaignData.ignoreDisliked}
                                                onChange={(e) => setDislikeFilter(e.target.checked)}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                Hide disliked creators of earlier campaigns
                                            </Typography>
                                        </Stack>
                                    </span>
                                    <span>
                                        <Stack
                                            direction="row"
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Checkbox
                                                label="hideLiked"
                                                checked={campaignData.ignoreLiked}
                                                onChange={(e) => setLikeFilter(e.target.checked)}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                Hide liked creators of earlier campaigns
                                            </Typography>
                                        </Stack>
                                    </span>
                                </Stack>
                            </Paper>
                        </Collapse>
                    </Grid>
                    <Grid item xs={6} md={6} />
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <Typography
                            textAlign="right"
                            sx={{
                                position: 'absolute',
                                top: '20px',
                                right: '0px',
                                fontSize: '0.7rem',
                                color: '#999999',
                            }}
                        >
                            Fields marked with * are mandatory.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

export default CampaignStepDescription;
