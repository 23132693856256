import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import NavigationBar from '../components/NavigationBar/NavigationBar';

function AppFrame(WrappedComponent) {
    function WrapperComponent() {
        const userReduxState = useSelector((state) => state.userReducer);
        const [isMobile, setIsMobile] = useState(false);
        const location = useLocation();

        useEffect(() => {
            // Function to handle the media query change
            const handleMediaQueryChange = (mediaQuery) => {
                setIsMobile(mediaQuery.matches);
            };

            // Create a media query that checks if the screen width is less than or equal to 768 pixels
            const mediaQuery = window.matchMedia('(max-width: 768px)');

            // Initial check
            handleMediaQueryChange(mediaQuery);

            // Add a listener to detect changes in the media query
            mediaQuery.addListener(handleMediaQueryChange);

            // Clean up the listener when the component unmounts
            return () => {
                mediaQuery.removeListener(handleMediaQueryChange);
            };
        }, []);

        return (
            <>
                <NavigationBar userReduxState={userReduxState} />
                <WrappedComponent isLoggedIn={userReduxState.isLoggedIn} isMobile={isMobile} />
            </>
        );
    }
    return WrapperComponent;
}

export default AppFrame;
