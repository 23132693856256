import { Container } from '@mui/material';
import { NavigationBar } from '../../components';
import AppFrame from '../../hoc/AppFrame';

const About = () => {
    return (
        <Container>
            <h1>About</h1>
        </Container>
    );
};

export default AppFrame(About);
