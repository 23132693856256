import React from 'react';
import { Typography, Container } from '@mui/material';

const PaymentSuccess = () => (
    <Container>
        <Typography>Payment successful!</Typography>
    </Container>
);

export default PaymentSuccess;
