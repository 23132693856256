import axios from 'axios';
import { getCurrentUserToken } from './';

export async function apiVersion() {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/version`,
    });
}

export async function userBrandRegistration(userData) {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/signup/brand`,
        data: userData,
    });
}

export async function userCreatorRegistration(userData) {
    const { claimedInstagramUsername, username, firstName, lastName, email } = userData;

    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/signup/creator`,
        data: {
            claimedInstagramUsername,
            username,
            email,
            firstName,
            lastName,
        },
    });
}

export async function invitedCreatorRegistration(creatorData) {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/signup/invitedCreator`,
        data: creatorData,
    });
}

export async function checkEmailAvailability(email) {
    if (email) {
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/signup/checkEmailAvailability/${email}`,
        });
    } else {
        return { data: { isEmailAvailable: true } };
    }
}

export async function checkUsernameAvailability(username) {
    if (username) {
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/signup/checkUsernameAvailability/${username}`,
        });
    } else {
        return { data: { isUsernameAvailable: true } };
    }
}

export async function checkInstagramUsernameAvailability(claimedInstagramUsername) {
    if (claimedInstagramUsername) {
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/signup/checkInstagramUsernameAvailability/${claimedInstagramUsername}`,
        });
    } else {
        return { data: { isInstagramUsernameAvailable: true } };
    }
}

export async function getUserType() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/usersAuth/getUserType`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getUser() {
    const userToken = getCurrentUserToken();
    if (userToken === null) {
        return Promise.reject('No user token');
    }

    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/usersAuth/getUser`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function updateUserProfile(userData) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/usersAuth/updateUser`,
        data: userData,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}
