import { Fragment } from 'react';

import { Box, Chip, chipClasses } from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    FiberManualRecord as FiberManualRecordIcon,
} from '@mui/icons-material';
import { green, teal, blue, pink, amber, brown } from '@mui/material/colors';

import {
    DRAFT,
    OFFER_INVITE,
    OFFER,
    DECLINED_OFFER,
    WAITING_FOR_DISPATCH,
    PRODUCT_SENT,
    SHIPPING_ISSUE,
    ONGOING,
    CANCELLED_ONGOING,
    PROOF_UPLOADED,
    FULFILLED,
} from '../../constants/missionStates';

export default ({ status }) => {
    let color = '';
    let item = '';

    switch (status) {
        case WAITING_FOR_DISPATCH:
            color = 'pink';
            item = (
                <Chip
                    label="Please send the product"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: teal['A700'],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case PRODUCT_SENT:
            color = 'pink';
            item = (
                <Chip
                    label="Product Sent"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: brown[300],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case SHIPPING_ISSUE:
            color = 'pink';
            item = (
                <Chip
                    label="Shipping Issue"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: pink[500],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;

        case DRAFT:
            color = 'gold';
            item = (
                <Box>
                    <Chip
                        label="campaign setup"
                        icon={<FiberManualRecordIcon />}
                        sx={{
                            background: 'transparent',
                            [`& .${chipClasses.icon}`]: {
                                borderWidth: '0px',
                                borderStyle: 'solid',
                                color: pink[500],
                                borderRadius: '20px',
                                width: '13px',
                                height: '13px',
                            },
                        }}
                    />
                </Box>
            );
            break;
        case OFFER_INVITE:
            color = 'deeppink';
            item = (
                <Box>
                    <Chip
                        label={'Please send the invite'}
                        icon={<FiberManualRecordIcon />}
                        sx={{
                            background: 'transparent',
                            [`& .${chipClasses.icon}`]: {
                                borderWidth: '0px',
                                borderStyle: 'solid',
                                color: teal['A700'],
                                borderRadius: '20px',
                                width: '13px',
                                height: '13px',
                            },
                        }}
                    />
                </Box>
            );
            break;
        case OFFER:
            color = 'deeppink';
            item = (
                <Chip
                    label="Offer sent to creator"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: amber[300],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case DECLINED_OFFER:
            color = 'darkviolet';
            item = (
                <Chip
                    label="Declined"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: pink[500],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case ONGOING:
            color = 'dodgerblue';
            item = (
                <Chip
                    label="In Progress"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: blue[400],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case CANCELLED_ONGOING:
            color = 'darkred';
            item = (
                <Chip
                    label="Cancelled"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: pink[500],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case FULFILLED:
            color = 'lime';
            item = (
                <Chip
                    label="Done"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: green[400],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        case PROOF_UPLOADED:
            color = 'lime';
            item = (
                <Chip
                    label="Proof Uploaded"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: teal['A700'],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
        default:
            color = 'lightgrey';
            item = (
                <Chip
                    label="Unknown"
                    icon={<FiberManualRecordIcon />}
                    sx={{
                        background: 'transparent',
                        [`& .${chipClasses.icon}`]: {
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            color: pink[700],
                            borderRadius: '20px',
                            width: '13px',
                            height: '13px',
                        },
                    }}
                />
            );
            break;
    }
    return <Fragment>{item}</Fragment>;
};
