import React from 'react';

import Button from '.';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';

const CheckboxButton = ({ buttonText, buttonStatus, handlerFunction }) => {
    return (
        <Button
            onClick={handlerFunction}
            endIcon={
                buttonStatus === true ? (
                    <CircleIcon sx={{ color: 'red' }} />
                ) : buttonStatus === false ? (
                    <CircleIcon sx={{ color: 'green' }} />
                ) : (
                    <RadioButtonUncheckedIcon />
                )
            }
            variant="outlined"
            sx={{
                marginBottom: '9px',
                marginRight: '9px',
                height: '70px',
                fontSize: 16,
                color: 'rgb(118, 118, 118)',
                backgroundColor: 'white',
                border: '1.5px solid #dbdbdc',
                borderRadius: '50px',
                boxShadow: 'none',
                paddingLeft: '25px',
                paddingRight: '25px',
                '&:hover': {
                    backgroundColor: 'white',
                    border: '1.5px solid #000000de',
                    boxShadow: 'none',
                },
            }}
        >
            {buttonText}
        </Button>
    );
};

export default CheckboxButton;
