import axios from 'axios';

import { getCurrentUserToken } from './';

export async function sendEmail(text, firstName, lastName, email, companyName, country) {
    const userToken = getCurrentUserToken();

    try {
        return axios({
            method: 'POST',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/contact-email`,
            data: {
                text,
                firstName,
                lastName,
                email,
                companyName,
                country,
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });
    } catch (error) {
        return 'Error sending email';
    }
}
