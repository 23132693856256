import axios from 'axios';
import { getCurrentUserToken } from './firebase';

export async function sendOrder(name, brandName, email, filteringOptionReference, items) {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/payment/create-order`,
        data: {
            name: name,
            brandName: brandName,
            email: email,
            filteringOptionReference: filteringOptionReference,
            items: items,
        },
    });
}

export async function getDemoCreators(creatorSearchFilterList) {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/demo/creators`,
        params: creatorSearchFilterList,
    });
}

export async function getOnePurchasedCreatorData(paymentIntentId, creatorId) {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/payment/purchase-creator-data`,
        params: {
            creatorId,
            paymentIntentId: paymentIntentId,
        },
    });
}
