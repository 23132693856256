import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Box,
    Card,
    CardActions,
    CardActionArea,
    Grid,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material/';
import { Instagram } from '@mui/icons-material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import Button from '../Button';
import LikeDislikeSelector from '../LikeDislikeSelector';
import PaymentDialog from '../PaymentDialog';

import { averageLikePerPostFormat } from '../../utils/formatters';

const CreatorCardStepRecommendation = ({
    creatorItem,
    updatePurchasedCreators,
    handleReactionButton,
    handleAddButton,
    handleRemoveButton,
    nextButtonTouched,
    isThisADemo,
    isThisMyCreatorsPage,
}) => {
    const { campaign: campaignData } = useSelector((state) => state.campaignSetupReducer);

    const [isSelected, setIsSelected] = useState(null);
    const [selectedLike, setSelectedLike] = useState(null);

    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [isThisCreatorPurchased, setIsThisCreatorPurchased] = useState(isThisMyCreatorsPage);

    const handleDialogOpenClose = () => setPaymentDialogOpen(!paymentDialogOpen);
    const handlePaymentDialogOpenClose = () => setPaymentDialogOpen(!paymentDialogOpen);

    useEffect(() => {
        const foundSelectedCreator = campaignData.reactionsToRecommendedCreators.find(
            (creator) => creator._id === creatorItem._id
        );
        if (foundSelectedCreator) {
            setSelectedLike(foundSelectedCreator.reaction);
        }

        const foundCreator = campaignData.missions.find(
            (mission) => mission.creator._id === creatorItem._id
        );
        if (foundCreator) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [campaignData]);

    const highestRatedLabel = (creator) =>
        creator.labels.reduce(
            (highestRatedLabel, label) => {
                if (label.rating > highestRatedLabel.rating) {
                    return label;
                }
                return highestRatedLabel;
            },
            { rating: 0, label: 'no label error' }
        );

    const formatNumbersToKiloDotDecimal = (number) => {
        let formattedNumber = parseInt(number);

        let postfixValue;
        let postfixedText;

        if (formattedNumber < 1000000) {
            postfixValue = Math.round(formattedNumber / 100) / 10;
            postfixedText = `${postfixValue} K`;
        } else {
            postfixValue = Math.round(formattedNumber / 100000) / 10;
            postfixedText = `${postfixValue} M`;
        }

        return postfixedText;
    };

    return (
        <Grid item xs={12} sm={6} md={6} lg={5}>
            <Card
                sx={{
                    position: 'relative',
                    borderRadius: '1vw',
                    padding: '0.5vw',
                    paddingBottom: '0.75vw',
                    '&:hover': {
                        //zIndex: '20000',
                        boxShadow: '1vw 1vh 3rem #CCCCCC',
                        transform: 'scale(1.01)',
                    },
                    backgroundColor: (theme) =>
                        nextButtonTouched && selectedLike === null
                            ? theme.palette.background.highlight
                            : theme.palette.background.main,
                }}
            >
                <CardActions
                    style={{
                        width: '100%',
                        p: '0',
                        m: '0',
                        position: 'absolute',
                        zIndex: '5',
                    }}
                >
                    <Stack direction="row" spacing={10}>
                        {!isThisADemo && (
                            <LikeDislikeSelector
                                creatorItem={creatorItem}
                                handleReactionButton={handleReactionButton}
                                selectedLike={selectedLike}
                                setSelectedLike={setSelectedLike}
                            />
                        )}
                    </Stack>
                    <Box
                        sx={{
                            width: '130px',
                            height: '50px',
                            backgroundColor: selectedLike === null ? '#efefef' : '#fff',
                            boxShadow:
                                selectedLike === null ? '5px 5px 20px 10px rgba(0,0,0,0.1)' : '',
                            position: 'absolute',
                            right: '0px',
                            top: '-7px',
                            borderRadius: '0px 10px 0px 25px',
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handlePaymentDialogOpenClose}
                            sx={{
                                position: 'absolute',
                                top: '8px',
                                left: '-20px',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                color: 'loveSelection.void',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    color: 'loveSelection.void',
                                },
                                '&:visited': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    color: 'loveSelection.void',
                                },
                            }}
                        >
                            See report
                        </Button>
                    </Box>
                </CardActions>

                <CardActionArea
                    sx={{
                        '&:before': {
                            content: "''",
                            display: 'block',
                            paddingTop: '100%' /* initial ratio of 1:1*/,
                        },
                        backgroundImage: `url(${creatorItem.profileImage}), url('https://images-vea4cdfsm.s3.eu-central-1.amazonaws.com/avatar.svg')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '1vw',
                        marginBottom: '1.1vh',
                    }}
                    onClick={handleDialogOpenClose}
                >
                    <Box
                        // fill horizontal space
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '13%',
                            padding: '0.5vw',
                            bottom: 0,
                            left: 0,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '0 0 1vw 1vw',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            color: 'white',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                color: 'white',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                textAlign: 'center',
                                padding: '0 5px',
                            }}
                        >
                            {creatorItem.instagramUsername}
                        </Typography>
                    </Box>
                </CardActionArea>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        spacing: 0,
                    }}
                    style={{ padding: '5px 14px 5px 5px' }}
                >
                    {/* see report button */}
                    <Stack direction="column" width="53%" spacing={0}>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                        >
                            {!isThisADemo || isThisCreatorPurchased ? (
                                <>
                                    {/* <Instagram /> */}
                                    {/* <Typography variant="body2">
                                            <Tooltip
                                                title={
                                                    creatorItem.instagramUsername
                                                }
                                                enterDelay={600}
                                            >
                                                <Link
                                                    href={`http://instagram.com/${creatorItem.instagramUsername}`}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    underline="hover"
                                                >
                                                    {creatorItem.instagramUsername.slice(
                                                        0,
                                                        12
                                                    ) <
                                                    creatorItem.instagramUsername
                                                        ? `${creatorItem.instagramUsername.slice(
                                                              0,
                                                              11
                                                          )}...`
                                                        : creatorItem.instagramUsername}
                                                </Link>
                                            </Tooltip>
                                        </Typography> */}
                                    <>
                                        <Button
                                            // go to http://instagram.com/${creatorItem.instagramUsername if clicked
                                            onClick={() =>
                                                window.open(
                                                    `http://instagram.com/${creatorItem.instagramUsername}`,
                                                    '_blank'
                                                )
                                            }
                                            startIcon={<Instagram />}
                                            size="small"
                                            sx={{
                                                color: 'white',
                                                backgroundColor: '#8E24AA',
                                                '&:hover': {
                                                    backgroundColor: '#5E35B1',
                                                },
                                            }}
                                        >
                                            Visit Profile
                                        </Button>
                                    </>
                                </>
                            ) : (
                                <Typography variant="h7" width={'140px'}>
                                    {creatorItem.name.slice(0, 12) < creatorItem.name
                                        ? `${creatorItem.name.slice(0, 12)}...`
                                        : creatorItem.name}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>

                    {/* creator metrics */}
                    <Stack direction="column" width="47%" alignItems={'flex-end'} spacing={0}>
                        <Box>
                            <Tooltip title={'followers count'} enterDelay={600}>
                                <Stack direction="row" spacing={0.2} alignItems="center">
                                    <PeopleAltIcon />
                                    <Typography fontSize="0.8rem">
                                        {formatNumbersToKiloDotDecimal(creatorItem.followersCount)}
                                    </Typography>
                                </Stack>
                            </Tooltip>
                            <Tooltip title={'average likes of last post'} enterDelay={600}>
                                <Stack direction="row" alignItems="end">
                                    <Typography fontSize="0.8rem">
                                        {` ${averageLikePerPostFormat(
                                            creatorItem.averageLikesOfLastPosts
                                        )}`}
                                        &nbsp;/ post
                                    </Typography>
                                </Stack>
                            </Tooltip>
                        </Box>
                    </Stack>
                </Stack>
            </Card>

            <PaymentDialog
                open={paymentDialogOpen}
                handleDialogOpenClose={handlePaymentDialogOpenClose}
                creator={creatorItem}
                updatePurchasedCreators={updatePurchasedCreators}
                isThisCreatorPurchased={isThisCreatorPurchased}
                setIsThisCreatorPurchased={setIsThisCreatorPurchased}
                isThisADemo={isThisADemo}
            />
        </Grid>
    );
};

export default CreatorCardStepRecommendation;
