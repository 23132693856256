import { Grid, Typography } from '@mui/material/';
import KeyIcon from '@mui/icons-material/Key';
import AppFrame from '../../hoc/AppFrame';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const ResetPasswordSuccessPage = () => {
    useAuthorization([roles.PUBLIC]);

    return (
        <Grid container marginTop="25vh">
            <Grid item xs={12} md={12}>
                <KeyIcon
                    sx={{
                        fontSize: '100px',
                        color: 'black',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
                <Typography
                    align="center"
                    sx={{
                        fontSize: '25px',
                        paddingTop: '30px',
                        marginBottom: '30px',
                    }}
                >
                    Your password reset request has been received
                </Typography>
                <Typography
                    align="center"
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    Please check your email and follow the instructions to reset your password.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AppFrame(ResetPasswordSuccessPage);
