import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LanguageIcon from '@mui/icons-material/Language'; // Import a Material-UI icon for demonstration
import Autocomplete from '@mui/material/Autocomplete';

export default function CountrySelect({ handleLanguageChange }) {
    const handleChange = async (event, value, reason) => {
        await handleLanguageChange(value.apiKeyword);

        if (reason === 'clear' || value === null) {
            // Re-select "Auto" if selection is cleared or becomes null
            value = languageList[0];
        }
    };

    return (
        <Autocomplete
            id="country-select-demo"
            sx={{ width: 300 }}
            options={languageList}
            autoHighlight
            defaultValue={languageList[0]} // Default value set to "Auto"
            onChange={handleChange}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <Box
                        key={key}
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...optionProps}
                    >
                        {option.code === 'AUTO' ? (
                            <LanguageIcon style={{ marginRight: '8px' }} />
                        ) : (
                            <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                            />
                        )}
                        {option.label}
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="comment language"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
            disableClearable // Disable the clear button
        />
    );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const languageList = [
    { code: 'AUTO', label: 'Auto', apiKeyword: 'auto' },
    { code: 'GB', label: 'English', apiKeyword: 'english' },
    { code: 'PT', label: 'Portugese', apiKeyword: 'portugese' },
    { code: 'ES', label: 'Spanish', apiKeyword: 'spanish' },
    { code: 'DE', label: 'German', apiKeyword: 'german' },
    { code: 'FR', label: 'French', apiKeyword: 'french' },
    { code: 'IT', label: 'Italian', apiKeyword: 'italian' },
];
