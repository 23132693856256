import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import {
    Tooltip,
    Stack,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

const PieChart = ({ data, width, height }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        drawChart();
    }, [data]);

    const [toolTipSettings, setToolTipSettings] = useState({
        showToolTip: false,
        top: 0,
        left: 0,
        value: '',
        key: '',
        ratioToAllFollowers: '',
        followers: '',
        states: [],
        code: '',
    });

    function mouseOverHandler(d, e) {
        setToolTipSettings({
            showToolTip: true,
            // top: e.clientY,
            // left: e.clientX,
            // top: e.screenY,
            // left: e.screenX,
            top: d.y,
            left: d.x,
            value: e.value,
            key: e.data.country,
            ratioToAllFollowers: e.data.ratioToAllFollowers,
            followers: e.data.followers,
            states: e.data.states,
            code: e.data.code,
        });
    }

    function mouseMoveHandler(e) {
        if (toolTipSettings.showToolTip) {
            setToolTipSettings({
                ...toolTipSettings,
                top: e.y,
                left: e.x,
            });
        }
    }

    function mouseOutHandler() {
        setToolTipSettings({
            ...toolTipSettings,
            showToolTip: false,
        });
    }

    const drawChart = () => {
        d3.select(chartRef.current).selectAll('svg').remove(); // Remove any existing SVG before drawing

        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const radius = Math.min(width, height) / 2;
        const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

        const pie = d3
            .pie()
            .value((d) => d.ratioToAllFollowers)
            .sort(null);

        const arc = d3.arc().innerRadius(0).outerRadius(radius);

        const arcs = svg
            .selectAll('arc')
            .data(pie(data))
            .enter()
            .append('g')
            .attr('class', 'arc')
            .attr('transform', `translate(${width / 2}, ${height / 2})`)
            .on('mouseover', mouseOverHandler)
            .on('mousemove', mouseMoveHandler)
            .on('mouseout', mouseOutHandler);

        arcs.append('path')
            .attr('d', arc)
            .attr('fill', (d, i) => colorScale(i));

        arcs.append('text')
            // .attr('transform', (d) => `translate(${arc.centroid(d)})`)
            // .attr('text-anchor', 'middle')
            // .text((d) => d.data.code);
            .attr('transform', (d) => {
                const [x, y] = arc.centroid(d);
                const labelX = x * 1.5 - 5; // Adjust this factor to control the label distance
                const labelY = y * 1.5; // Adjust this factor to control the label distance
                return `translate(${labelX}, ${labelY})`;
            })
            .attr('text-anchor', 'middle')
            .text((d) => {
                // if it is 2 percent or smaller, don't show the label
                if (d.data.ratioToAllFollowers <= 0.02 || d.data.followers === 0) {
                    return '';
                }

                return d.data.code;
            });
    };

    return (
        <>
            <div className="pie-chart-container" width="200px" ref={chartRef}></div>
            {toolTipSettings.showToolTip && (
                <div
                    style={{
                        position: 'absolute',
                        top: toolTipSettings.top,
                        left: toolTipSettings.left,
                        backgroundColor: 'white',
                        padding: '10px',
                    }}
                >
                    <Stack direction="column" spacing={1}>
                        <Typography>
                            {`${toolTipSettings.key}: ${formatRatioToRoundedPercentage(
                                toolTipSettings.ratioToAllFollowers
                            )}%`}
                        </Typography>
                        <Typography>{`followers: ~${toolTipSettings.followers}`}</Typography>

                        {toolTipSettings.states && toolTipSettings.states.length > 0 && (
                            <UsaStatesTable states={toolTipSettings.states} />
                        )}
                    </Stack>
                </div>
            )}
        </>
    );
};

const UsaStatesTable = ({ states }) => {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell align="right">share</TableCell>
                        <TableCell align="right">followers</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {states.map((row) => (
                        <TableRow
                            key={row.state}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.state}
                            </TableCell>
                            <TableCell align="right">
                                {formatRatioToRoundedPercentage(row.ratioToAllFollowers)}%
                            </TableCell>
                            <TableCell align="right">{row.count}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

function formatRatioToRoundedPercentage(ratio) {
    return (ratio * 100).toFixed(0);
}

export default PieChart;
