import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography, Link as MuiLink } from '@mui/material';

import { ReactComponent as Logo } from '../../images/wcxbcl.svg';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';
import Button from '../../components/Button';
import 'firebase/auth';
import { verifyUserByOobCode } from '../../services/firebase';
import ResetNewPassword from './ResetNewPassword';
import AppFrame from '../../hoc/AppFrame';

const Verification = () => {
    useAuthorization([roles.PUBLIC]);

    const location = useLocation();

    const [modeFromUrl, setModeFromUrl] = useState(null);
    const [oobCodeFromUrl, setOobCodeFromUrl] = useState(null);

    useEffect(() => {
        const verificationFunction = async () => {
            const searchParams = new URLSearchParams(location.search);
            const mode = searchParams.get('mode');
            const oobCode = searchParams.get('oobCode');

            setModeFromUrl(mode);
            setOobCodeFromUrl(oobCode);
        };

        verificationFunction();
    }, []);

    return (
        <>
            {modeFromUrl === 'resetPassword' && <ResetNewPassword oobCode={oobCodeFromUrl} />}
            {modeFromUrl === 'verifyEmail' && <EmailVerification oobCode={oobCodeFromUrl} />}
            {modeFromUrl !== 'resetPassword' && modeFromUrl !== 'verifyEmail' && (
                <Box
                    sx={{
                        paddingTop: (theme) => theme.NavBar.height,
                        height: '100vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingBottom: '20%',
                        paddingLeft: '10%',
                        paddingRight: '10%',
                    }}
                >
                    <Typography>Invalid verification link.</Typography>
                </Box>
            )}
        </>
    );
};

const EmailVerification = ({ oobCode }) => {
    const [verificationStatus, setVerificationStatus] = useState(null);

    useEffect(() => {
        const verificationFunction = async () => {
            try {
                if (oobCode) {
                    // const verificationResponse =
                    await verifyUserByOobCode(oobCode);
                    setVerificationStatus('success');
                }
            } catch (error) {
                if (error.code === 'auth/invalid-action-code') {
                    setVerificationStatus('invalidActionCode');
                } else {
                    setVerificationStatus('error');
                }
            }
        };

        verificationFunction();
    }, []);

    return (
        <>
            {!verificationStatus && <LoadingView />}

            {verificationStatus &&
                (verificationStatus === 'success' ? (
                    <VerificationSuccessfulView />
                ) : verificationStatus === 'error' ? (
                    <VerificationFailedView />
                ) : verificationStatus === 'invalidActionCode' ? (
                    <InvalidActionCodeView />
                ) : (
                    <VerificationFailedView />
                ))}
        </>
    );
};

const InvalidActionCodeView = () => {
    return (
        <Stack
            sx={{
                paddingTop: (theme) => theme.NavBar.height,
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                paddingLeft: '10%',
                paddingRight: '10%',
            }}
        >
            <Logo
                width="250px"
                height="35px"
                sx={{
                    marginBottom: '20px',
                }}
            />
            <Typography
                variant="h4"
                align="center"
                sx={{
                    paddingBottom: '20px',
                }}
            >
                <br />
                Invalid verification code.
                <br />
                You may have already verified your email address.
                <br />
                Please go to{' '}
                <MuiLink href="/login" underline="none">
                    Login page
                </MuiLink>{' '}
                or contact support on{' '}
                <MuiLink href="mailto:support@wineconx.com" underline="none">
                    support@wineconx.com
                </MuiLink>
            </Typography>
        </Stack>
    );
};

const VerificationSuccessfulView = () => {
    return (
        <Stack
            sx={{
                paddingTop: (theme) => theme.NavBar.height,
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                paddingLeft: '10%',
                paddingRight: '10%',
            }}
        >
            {' '}
            <Logo
                width="250px"
                height="35px"
                sx={{
                    marginBottom: '20px',
                }}
            />
            <Typography
                variant="h5"
                align="center"
                sx={{
                    paddingBottom: '20px',
                }}
            >
                Email verification successful!
                <br />
                You can now access your account.
                <br />
                Click here to login.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/login">
                Login
            </Button>
        </Stack>
    );
};

const VerificationFailedView = () => {
    return (
        <Stack
            sx={{
                paddingTop: (theme) => theme.NavBar.height,
                height: '100vh',
                // vertically center
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                paddingLeft: '10%',
                paddingRight: '10%',
            }}
        >
            <Logo
                width="250px"
                height="35px"
                sx={{
                    marginBottom: '20px',
                }}
            />
            <Typography
                variant="h5"
                align="center"
                sx={{
                    paddingBottom: '20px',
                }}
            >
                Verification failed.
                <br />
                Please try again or contact support.
            </Typography>
        </Stack>
    );
};

const LoadingView = () => {
    return (
        <Stack
            sx={{
                paddingTop: (theme) => theme.NavBar.height,
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    paddingBottom: '20px',
                }}
            >
                Verifying your email address...
            </Typography>
            <CircularProgress />
        </Stack>
    );
};

export default AppFrame(Verification);
