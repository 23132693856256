import React, { useState } from 'react';
import { Divider, IconButton, MenuItem, Menu } from '@mui/material';
import { Add } from '@mui/icons-material';

import { addLikedCreatorToCampaignRecommendation } from '../../services/apiRecommendation';

const CardItemMenuDropdown = ({
    campaigns,
    creatorId,
    setShowAddedCreatorNotification,
    setNotificationType,
    handleAddNewListMenuClick,
}) => {
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(null);
    const open = Boolean(dropdownMenuOpen);

    const handleAddClick = (event) => {
        setDropdownMenuOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setDropdownMenuOpen(null);
    };

    const handleItemClick = async (campaign) => {
        const response = await addLikedCreatorToCampaignRecommendation(campaign._id, creatorId);
        if (response.status === 200) {
            setNotificationType('success');
            setShowAddedCreatorNotification(true);
        } else if (response.status === 304) {
            setNotificationType('exists');
            setShowAddedCreatorNotification(true);
        } else if (response.status === 404) {
            setNotificationType('error');
            setShowAddedCreatorNotification(true);
        } else {
            setNotificationType('error');
            setShowAddedCreatorNotification(true);
        }
        handleCloseMenu();
    };

    const scrollableSectionStyle = {
        maxHeight: campaigns.length > 6 ? 200 : 'auto',
        overflow: 'auto',
    };

    return (
        <div>
            <IconButton onClick={handleAddClick} color="primary" sx={{ marginTop: '5px' }}>
                <Add />
            </IconButton>
            <Menu
                id="new-list-menu"
                anchorEl={dropdownMenuOpen}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{ 'aria-labelledby': 'new-list-button' }}
            >
                <div style={scrollableSectionStyle}>
                    {campaigns.map((campaign, index) => (
                        <MenuItem
                            key={`menu-item-${index}`}
                            onClick={() => handleItemClick(campaign)}
                        >
                            Add to {campaign.title}
                        </MenuItem>
                    ))}
                </div>
                <Divider />
                <MenuItem
                    onClick={() => {
                        handleAddNewListMenuClick();
                        handleCloseMenu();
                    }}
                    sx={{ marginTop: '10px', fontWeight: 'bold' }}
                >
                    Create new Campaign
                </MenuItem>
            </Menu>
        </div>
    );
};

export default CardItemMenuDropdown;
