import React, { useState, useEffect } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    textField: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                backgroundColor: 'white',
            },
        },
    },
    emptyTextField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
        },
    },
    filledTextField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'lightblue',
        },
        // not focused
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'aliceblue',
        },
    },
});

function PhoneTextField({ phoneNumber, setPhoneNumber }) {
    const [key, setKey] = useState(Date.now());
    const classes = useStyles();

    // Use effect to re-render the component in case of any internal state issues
    useEffect(() => {
        setKey(Date.now());
    }, []);

    return (
        <div key={key}>
            <MuiTelInput
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry={'US'}
                id="phone-number"
                name="phone-number"
                fullWidth
                className={
                    classes.textField +
                    ' ' +
                    (phoneNumber ? classes.filledTextField : classes.emptyTextField)
                }
            />
        </div>
    );
}

export default PhoneTextField;
