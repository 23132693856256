import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    textField: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                backgroundColor: 'white',
            },
        },
    },
    emptyTextField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
        },
    },
    filledTextField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'lightblue',
        },
        // not focused
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'aliceblue',
        },
    },
});

const TextFieldWithCaption = ({
    caption,
    value,
    onChange,
    id,
    error,
    helperText,
    ...textFieldProps
}) => {
    const classes = useStyles();

    return (
        <div style={{ marginBottom: '20px' }}>
            <Typography variant="caption" style={{ marginBottom: '8px' }}>
                {caption}
            </Typography>
            <TextField
                id={id}
                hiddenLabel
                fullWidth
                variant="outlined"
                value={value}
                onChange={onChange}
                className={
                    classes.textField +
                    ' ' +
                    (value ? classes.filledTextField : classes.emptyTextField)
                }
                error={error}
                helperText={error ? helperText : ''}
                {...textFieldProps}
            />
        </div>
    );
};

export default TextFieldWithCaption;
