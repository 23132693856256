import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { setLoginState, resetUserState, setPublicUserView } from '../redux/userData/actions';
import { setVoucherCount } from '../redux/voucher/actions';
import { authenticationObserver, logOutFirebase } from '../services';
import { getUser, getUserType } from '../services/apiAuth';
// import roles from './roles';

// This hook is responsible for updating the user state in redux every time the user logs in or logs out
// no other components should change the user role in redux
const useAuthSetup = () => {
    // console.log('🔵 [useAuthSetup]');
    const dispatch = useDispatch();

    useEffect(() => {
        async function unsubscribeEffect() {
            const unsubscribe = await authenticationObserver(async (firebaseUser) => {
                if (firebaseUser && firebaseUser.emailVerified) {
                    try {
                        // getting user from database
                        const userTypeResponse = await getUserType();
                        const userDataResponse = await getUser();

                        if (userDataResponse && userTypeResponse) {
                            // if user role changed, update state, otherwise do nothing

                            const userData = userDataResponse.data;
                            const userTypeFromDb = userTypeResponse.data;

                            dispatch(
                                setLoginState({
                                    isLoggedIn: true,
                                    userType: userTypeFromDb,
                                    user: userData,
                                    loading: false,
                                })
                            );
                            if (userData.brand) {
                                dispatch(setVoucherCount(userData.brand.freeCreatorCounter));
                            }

                            // console.log(
                            //     `  [useAuthSetup] LOGIN 🟢 - ${userTypeFromDb} `
                            // );
                        } else {
                            // console.log(
                            //     `  [useAuthSetup] LOGIN 🔴 - ${roles.PUBLIC}`
                            // );
                            // console.log(
                            //     '  firebaseUser is not in db, reset all auth to default'
                            // );
                            logOutFirebase();
                            dispatch(resetUserState());
                        }
                    } catch (error) {
                        console.error('   [useAuthSetup] Failed to fetch user data:', error);
                    }
                } else {
                    // console.log(
                    //     `  [useAuthSetup] LOGIN 🔴 - ${roles.PUBLIC}, no userType`
                    // );
                    dispatch(setPublicUserView());
                }
            });

            // Cleanup subscription on unmount.
            return () => {
                if (unsubscribe) {
                    unsubscribe();
                }
            };
        }

        unsubscribeEffect();
    }, [dispatch]);
};

export default useAuthSetup;
