export function engagementRateFormat(engagementRate) {
    return `${Math.round(engagementRate * 1000) / 10} %`;
}

export function averageLikePerPostFormat(averageLikePerPost) {
    if (averageLikePerPost > 999) {
        return `❤️ ${Math.round(averageLikePerPost / 1000)} K`;
    } else {
        return `❤️ ${Math.round(averageLikePerPost)}`;
    }
}

export function commentPerPostFormat(averageLikePerPost) {
    if (averageLikePerPost > 999) {
        return `💬 ${Math.round(averageLikePerPost / 1000)} K`;
    } else {
        return `💬 ${Math.round(averageLikePerPost)}`;
    }
}
