export const DRAFT = 'DRAFT';
export const OFFER_INVITE = 'OFFER_INVITE';
export const OFFER = 'OFFER';
export const DECLINED_OFFER = 'DECLINED_OFFER';
export const WAITING_FOR_DISPATCH = 'WAITING_FOR_DISPATCH';
export const PRODUCT_SENT = 'PRODUCT_SENT';
export const SHIPPING_ISSUE = 'SHIPPING_ISSUE';
export const ONGOING = 'ONGOING';
export const CANCELLED_ONGOING = 'CANCELLED_ONGOING';
export const PROOF_UPLOADED = 'PROOF_UPLOADED';
export const FULFILLED = 'FULFILLED';
