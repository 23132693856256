import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    Stack,
    Divider,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';

import AppFrame from '../../hoc/AppFrame';
import { BlockSubHeading, MainH1FirstLine, MainHeadingHalfContainer, Section } from '../Home/Parts';
import ScrollHomeButton from '../../components/ScrollHomeButton';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';

const typographyBase = {
    fontFamily: 'Rubik',
    fontWeight: '300',
    lineHeight: '1.4',
};

const services = {
    creator: ['register and get special offers', 'get followers and interactions'],
    brand: [
        'get the best influencers with fitting target audience',
        'with our AI toolset we can find potential followers and increase the number of interactions',
        'we manage the content schedule for your social media profile',
        'we can customize your content',
        'report on the growth of your profile',
    ],
    brandAlternate: [
        'Direct access to our smart tools',
        'you search our system for the best fitting influencers by your preferred parameters',
        'Automated interactions with your target audience',
    ],
    agency: [
        'Direct access to our smart tools',
        'you search our system for the best fitting influencers by your preferred parameters',
        'find the most relevants posts on Instagram',
        'improve interactions with any Instagram profile using our AI tool',
        'analyze your client’s profile and campaign results',
    ],
    fullservice: [
        'Manage your brand’s Instagram social media presence. ',
        'Find new followers and increase quantity of interactions',
        'Manage your posting and content schedule',
        'Find the best influencers with targeted audiences',
        'Customization of your content for optimal results',
        'Provides ongoing reporting on the metrics of your profile',
    ],
    doityourself: [
        'Direct access to our AI smart tools',
        'WIneConX will set your preferred parameters',
        'Access to search for the best influencers for your brand',
        'Automated interactions with your target audience',
        'Find the most relevant posts on Instagram',
        'Improve interactions with any Instagram profile using our AI tool',
        'Analyze your client’s profile and campaign results',
    ],
};

const Services = () => {
    useAuthorization([roles.PUBLIC]);

    return (
        <>
            <Section bgC="white" paddingTop="100px" paddingBottom="0px">
                <MainHeadingHalfContainer>
                    <MainH1FirstLine textAlign="center">Services</MainH1FirstLine>

                    <BlockSubHeading textAlign="center">
                        Discover the power and ease of influencer marketing with WineConX
                    </BlockSubHeading>
                </MainHeadingHalfContainer>
            </Section>

            <Section>
                <Container>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} md={5}>
                            <ServiceList
                                title="Full Service"
                                subtitle="Hire WineConX to increase your brand awareness and sales."
                                points={services.fullservice}
                                monthlyPrice="990"
                                annualPrice="9990"
                                discount="20"
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <ServiceList
                                title="Do it yourself"
                                subtitle="Receive access to our AI smart tools to help build followers, engagement, find the best influencers for your brand and get progress reports."
                                points={services.doityourself}
                                monthlyPrice="500"
                                annualPrice="5000"
                                discount="20"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        </>
    );
};

const ServiceList = ({
    title,
    subtitle,
    points,
    alternatePoints,
    monthlyPrice,
    annualPrice,
    discount,
}) => (
    <Paper
        elevation={3}
        style={{
            position: 'relative',
            borderRadius: '12px',
            border: '1px solid #aaaaaa',
            boxShadow: 'none',
            padding: '24px',
            marginBottom: '16px',
            paddingBottom: '100px',
        }}
    >
        <Box
            sx={{
                minHeight: '144px',
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{
                    ...typographyBase,
                    textTransform: 'uppercase',
                    fontSize: '20px',
                    marginBottom: '16px',
                    fontWeight: '400',
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="subtitle2"
                sx={{
                    ...typographyBase,
                    fontSize: '16px',
                    marginBottom: '16px',
                }}
            >
                {subtitle}
            </Typography>
            <Typography
                variant="subtitle2"
                sx={{
                    ...typographyBase,
                    fontSize: '16px',
                    marginBottom: '16px',
                }}
            >
                <Box
                    component="span"
                    sx={{
                        fontWeight: 'bold',
                        //the color should be the same as the primary color
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    ${monthlyPrice}
                </Box>{' '}
                per month. Or pay{' '}
                <Box
                    component="span"
                    sx={{
                        //text decoration should be line-through
                        textDecoration: 'line-through',
                    }}
                >
                    ${Number(monthlyPrice) * 12}
                </Box>{' '}
                ${annualPrice} for a year.
            </Typography>
        </Box>

        <Divider
            sx={{
                marginY: '16px',
            }}
        />

        <List>
            {points.map((point, index) => (
                <ListItem key={index}>
                    <Stack direction="row" alignItems="top">
                        <CheckIcon
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                                marginRight: '8px',
                                marginTop: '4px',
                            }}
                        />
                        <ListItemText
                            sx={{
                                '& span': {
                                    ...typographyBase,
                                    fontSize: '16px',
                                },
                            }}
                            primary={point}
                        />
                    </Stack>
                </ListItem>
            ))}
        </List>

        {alternatePoints && (
            <>
                <Divider
                    sx={{
                        marginY: '36px',
                    }}
                />

                <Typography
                    variant="subtitle2"
                    sx={{
                        ...typographyBase,
                        fontSize: '16px',
                        marginBottom: '16px',
                    }}
                >
                    You can also choose to get access to our smart tools and work on your campaign
                    yourself:
                </Typography>
                <List>
                    {alternatePoints.map((point, index) => (
                        <ListItem key={index}>
                            <Stack direction="row" alignItems="top">
                                <CheckIcon
                                    sx={{
                                        color: (theme) => theme.palette.primary.main,
                                        marginRight: '8px',
                                        marginTop: '4px',
                                    }}
                                />
                                <ListItemText
                                    sx={{
                                        '& span': {
                                            ...typographyBase,
                                            fontSize: '16px',
                                        },
                                    }}
                                    primary={point}
                                />
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            </>
        )}

        <ScrollHomeButton
            to="contact"
            variant="contained"
            sx={{
                position: 'absolute',
                bottom: '0',
                width: 'calc(100% - 48px)',
                marginBottom: '24px',
                fontFamily: 'Rubik',
                marginTop: '24px',
                fontSize: '14px',
                height: '48px',
                fontWeight: 450,
                color: 'white',
                backgroundColor: '#de4564',
                display: {
                    //xs: 'none',
                    md: 'inline-block',
                },
                borderRadius: '25px',
                '&:hover': {
                    backgroundColor: '#e85f7b',
                },
            }}
        >
            get {discount}% off now
        </ScrollHomeButton>
    </Paper>
);

export default AppFrame(Services);
