import React, { useEffect, useState } from 'react';

import { Box, Dialog, Stack, IconButton, Typography, Link, Container } from '@mui/material/';
import { Close, Instagram } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';

import PieChart from '../PieChart/PieChart';
import { InstagramEmbed } from 'react-social-media-embed';

import {
    commentPerPostFormat,
    averageLikePerPostFormat,
    engagementRateFormat,
} from '../../utils/formatters';
import { useTheme } from '@mui/material/styles';

const ReportDialog = ({ open, onClose, creatorItem, campaigns, handleDialogOpenClose }) => {
    const theme = useTheme();
    const [pieChartData, setPieChartData] = useState([]);

    useEffect(() => {
        if (!creatorItem.audienceLocations) return;

        const audienceLocationsWithFollowerCount = creatorItem.audienceLocations.map((location) => {
            const followers =
                Math.round((creatorItem.followersCount * location.ratioToAllFollowers) / 100) * 100;
            return {
                country: location.country,
                code: location.code,
                ratioToAllFollowers: location.ratioToAllFollowers,
                followers: followers,
                states: location.states,
            };
        });

        const usa = audienceLocationsWithFollowerCount.find(
            (location) => location.country === 'USA'
        );
        let topFiveStates = [];
        if (usa) {
            topFiveStates = usa.states
                .sort((a, b) => b.followers - a.followers)
                .slice(0, 5)
                .map((state) => {
                    return {
                        state: state.state,
                        code: state.code,
                        amount: state.amount,
                        ratioToAllFollowers: state.ratioToAllFollowers,
                        count: Math.round((usa.followers * state.ratioToAllFollowers) / 100) * 100,
                    };
                })
                .filter((state) => state.count > 50)
                // filter out if state name is null, undefined or empty string
                .filter(
                    (state) =>
                        state.state &&
                        state.state !== 'null' &&
                        state.state !== 'undefined' &&
                        state.state !== ''
                );

            audienceLocationsWithFollowerCount.find(
                (location) => location.country === 'USA'
            ).states = topFiveStates;
        }

        setPieChartData(audienceLocationsWithFollowerCount);
    }, [creatorItem]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            sx={{
                marginTop: theme.NavBar.height,
            }}
        >
            {/* Close button */}
            <IconButton
                aria-label="close"
                onClick={handleDialogOpenClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    backgroundColor: 'white',
                }}
            >
                <Close />
            </IconButton>

            <Container>
                <Stack direction="column" sx={{ marginTop: '20px' }}>
                    {/* Profile */}
                    <Stack
                        direction="row"
                        // spaces between
                        justifyContent={'space-between'}
                    >
                        <Stack direction="row" spacing={5}>
                            {/* Profile image */}
                            <Box
                                sx={{
                                    width: '200px',
                                    height: '200px',
                                    backgroundImage: `url(${creatorItem.profileImage}), url('https://images-vea4cdfsm.s3.eu-central-1.amazonaws.com/avatar.svg')`,
                                    backgroundSize: 'auto 100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            />
                            {/* Instagram user */}
                            <Stack direction="column">
                                <Typography
                                    id="Dialog-Dialog-title"
                                    variant="h6"
                                    component="h2"
                                    paddingBottom={2}
                                    marginTop={1}
                                >
                                    {creatorItem.name}
                                </Typography>

                                <Stack direction="row" spacing={1}>
                                    <Instagram />
                                    <Typography variant="body2" color="text.secondary">
                                        <Link
                                            href={`http://instagram.com/${creatorItem.instagramUsername}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            underline="hover"
                                        >
                                            {creatorItem.instagramUsername}
                                        </Link>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            {/* following stat names   */}
                            <Stack direction="column" spacing={1} paddingTop={2}>
                                <Typography>followers</Typography>
                                <Typography>likes/post</Typography>
                                <Typography>comments/post</Typography>
                                <Typography>engagement</Typography>
                                {creatorItem.country && <Stack direction="row">country</Stack>}
                            </Stack>
                            {/* following stat values */}
                            <Stack
                                direction="column"
                                spacing={1}
                                paddingTop={2}
                                sx={{ paddingLeft: '20px', width: '150px' }}
                            >
                                <Typography>{creatorItem.followersCount}</Typography>
                                <Typography>
                                    {`${averageLikePerPostFormat(
                                        creatorItem.averageLikesOfLastPosts
                                    )}`}
                                </Typography>
                                <Typography>
                                    {`${commentPerPostFormat(
                                        creatorItem.averageCommentsOfLastPosts
                                    )}`}
                                </Typography>
                                <Typography>
                                    {`${engagementRateFormat(creatorItem.engagementRate)}`}
                                </Typography>
                                <Typography width="300px">
                                    {creatorItem.country && `${creatorItem.country}`}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/* Stats */}
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            padding: ' 15px 20px',
                        }}
                    >
                        {creatorItem.website && (
                            <Stack direction="row" spacing={1}>
                                <LinkIcon />
                                <Typography variant="body2" color="text.secondary">
                                    <Link
                                        href={`${creatorItem.website}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        underline="hover"
                                    >
                                        {creatorItem.website.replace(/^https?:\/\//, '')}
                                    </Link>
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    {creatorItem.audienceLocations && creatorItem.audienceLocations.length > 0 && (
                        <Stack direction="column" alignItems="center" paddingBottom={10}>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                                sx={{
                                    margin: '20px',
                                }}
                            >
                                Audience Locations
                            </Typography>

                            {pieChartData.length > 0 && (
                                <PieChart data={pieChartData} width={400} height={400} />
                            )}
                            {/* <PieChart
                                    data={creatorItem.audienceLocations}
                                    width={400}
                                    height={400}
                                /> */}
                        </Stack>
                    )}
                </Stack>
                <Stack direction="column" spacing={2} sx={{ padding: '20px' }}>
                    {creatorItem.posts &&
                        creatorItem.posts.map((post, index) => {
                            return (
                                <Stack direction="row" key={index} spacing={2}>
                                    <Box>
                                        <InstagramEmbed
                                            url={'https://www.instagram.com/p/' + post.shortcode}
                                        />
                                    </Box>

                                    <Typography variant="body2" color="text.secondary">
                                        {post.mediaToCaption}
                                    </Typography>
                                </Stack>
                            );
                        })}
                </Stack>
            </Container>
        </Dialog>
    );
};

export default ReportDialog;
