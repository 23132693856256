import { Stack } from '@mui/material';

import AppFrame from '../../hoc/AppFrame';
import DescriptionFormPublic from '../../components/DescriptionFormPublic';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const DescriptionBrand = () => {
    useAuthorization([roles.BRAND]);

    return (
        <Stack
            direction="column"
            sx={{
                paddingTop: (theme) => theme.NavBar.height,
                height: '100%',
            }}
        >
            <h2>BrandDescription</h2>
            <DescriptionFormPublic />
        </Stack>
    );
};

export default AppFrame(DescriptionBrand);
