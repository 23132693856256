import { Helmet } from 'react-helmet';

const PageMetaData = ({
    title = 'WineConX | Influencer Marketing',
    description = 'Social media wine influencer marketing. Forget about hours of scrolling through Instagram. Get influencers by follower analysis.',
    image = 'https://wineconximages.s3.eu-central-1.amazonaws.com/website/landing6ItemsSnapshot.png',
    url = 'https://wineconx.app/',
    canonical = 'https://wineconx.app/',
}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:url" content={url} />
            <link rel="canonical" href={canonical} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {image && <meta property="og:image" content={image} />}
        </Helmet>
    );
};

export default PageMetaData;
