import initialState from './state';

import { SET_GUEST_SESSION_IDENTIFIER } from './types';

const guestUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GUEST_SESSION_IDENTIFIER:
            return {
                ...state,
                guestSessionIdentifier: action.payload,
            };
        default:
            return state;
    }
};

export default guestUserReducer;
