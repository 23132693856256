import React, { useState, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Avatar, Menu, MenuItem, Divider, IconButton, Tooltip } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';

import { logOutFirebase } from '../../services';
import roles from '../../hooks/roles';

const AccountMenu = (props) => {
    const navigate = useNavigate();

    const { userType } = props.userObject;
    const { personal, brand, creator } = props.userObject.user;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogoutClick = () => {
        logOutFirebase();
        navigate('/logout');
    };
    return (
        <Fragment>
            <Box
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    // display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    // align vertically middle
                }}
            >
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar
                            sx={{
                                width: 65,
                                height: 65,
                                border: '4px solid white',
                            }}
                            // alt={`avatar of user ${personal.firstName} ${personal.lastName}`}
                            alt={`avatar of user ${personal?.username}`}
                            src={personal?.profileImage}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                disableScrollLock={true}
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {userType === roles.ADMIN && (
                    <MenuItem
                        disabled={userType === roles.ADMIN}
                        color="inherit"
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        ADMIN
                    </MenuItem>
                )}
                {userType === 'creator' && (
                    <MenuItem
                        color="inherit"
                        component={Link}
                        to={'/creator/profile'}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Avatar
                            sx={{ width: 32, height: 32 }}
                            alt={`avatar of creator ${creator.name} `}
                            src={creator.profileImage}
                        />
                        {creator.name} profile
                    </MenuItem>
                )}
                {userType === 'brand' && (
                    <MenuItem
                        size="large"
                        color="inherit"
                        component={Link}
                        to={'/brand/profile'}
                        sx={{
                            color: (theme) => theme.palette.grey[900],
                        }}
                    >
                        <Avatar
                            sx={{ width: 32, height: 32 }}
                            //alt={`avatar of user ${personal.firstName} ${personal.lastName}`}
                            alt={`avatar of user ${personal.username}`}
                            src={brand.profileImage}
                        />
                        {brand.brandName} profile
                    </MenuItem>
                )}

                <MenuItem color="inherit" onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default AccountMenu;
