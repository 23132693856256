import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Stack, Typography } from '@mui/material';

import './Payment.css';
import { sendOrder } from '../../services/apiDemo';
import CheckoutForm from './CheckoutForm';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_API_KEY}`);

export default function Payment({ formParams, creatorId, revealPurchasedCreator }) {
    const [clientSecret, setClientSecret] = useState('');
    const [payment, setPayment] = useState({});

    const getPaymentId = async (paymentIntentId) => {
        revealPurchasedCreator(paymentIntentId, creatorId);
    };

    useEffect(() => {
        async function handleSetOrder() {
            const { email, name, brandName } = formParams;
            const orderResponse = await sendOrder(name, brandName, email, {}, [creatorId]);

            setClientSecret(orderResponse.data.clientSecret);
            setPayment({
                currency: orderResponse.data.payment.currency.toUpperCase(),
                amount: orderResponse.data.payment.amount / 100,
            });
        }
        handleSetOrder();
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <Box>
            {clientSecret && (
                <>
                    <Stack
                        direction="column"
                        fullWidth
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography textTransform="uppercase" marginBottom={1}>
                            Checkout
                        </Typography>
                        <Typography variant="body2">
                            Buy creator information for {payment.amount} {payment.currency}
                        </Typography>
                    </Stack>
                </>
            )}
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm
                        getPaymentId={getPaymentId}
                        payment={payment}
                        email={formParams.email}
                    />
                </Elements>
            )}
        </Box>
    );
}
