import React, { useEffect, useState } from 'react';
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';

import { Alert, Box } from '@mui/material';

import Button from '../Button/Button';

export default function CheckoutForm({ getPaymentId, payment, email }) {
    const stripe = useStripe();
    const elements = useElements();

    const [stripeErrorMessage, setStripeErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setStripeErrorMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setStripeErrorMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setStripeErrorMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setStripeErrorMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const stripePaymentAttemptResponse = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // TODO: why we have to put it here if never used?
                // if this is not provided, Stripe has an alert
                // we stop redirection by redirect = 'if_required',
                // but it is still required to be provided
                return_url: 'http://localhost:3000/payment/success',
            },
            redirect: 'if_required',
        });

        const error = stripePaymentAttemptResponse.error;

        if (error) {
            ReactGA.event({
                category: 'Payment',
                action: `WX: Payment failed`,
                label: error.message,
                value: error.type,
            });

            if (error.type === 'card_error' || error.type === 'validation_error') {
                setStripeErrorMessage(error.message);
            } else {
                setStripeErrorMessage('An unexpected error occurred.');
            }
            setIsLoading(false);
        } else {
            const paymentIntentId = stripePaymentAttemptResponse.paymentIntent.id;
            await getPaymentId(paymentIntentId);

            ReactGA.event({
                category: 'Payment',
                action: 'WX: Payment succeeded',
                label: `Payment succeeded`,
                value: 1,
            });
        }
    };

    const paymentElementOptions = {
        layout: 'tabs',
    };

    return (
        <Box marginTop="20px" marginBottom="0px">
            <form id="payment-form" onSubmit={handleSubmit}>
                {/*
              TODO: comment LinkAuthenticationElement out and save email to stripe from our form
             */}
                <Box hidden>
                    <LinkAuthenticationElement
                        id="link-authentication-element"
                        // onChange={(e) => {
                        //     console.log(e);
                        //     // setEmail(e.target.value)
                        //     // setEmail(e.value);
                        // }}
                        options={{
                            defaultValues: {
                                email: email,
                            },
                        }}
                    />
                </Box>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="contained"
                        disabled={isLoading || !stripe || !elements}
                        // id="submit"
                        type="submit"
                        sx={{
                            marginTop: '20px',
                            marginBottom: '20px',
                        }}
                    >
                        <span id="button-text">
                            {isLoading ? (
                                <div className="spinner" id="spinner">
                                    Loading
                                </div>
                            ) : (
                                `Pay now ${payment.amount} ${payment.currency}`
                            )}
                        </span>
                    </Button>
                </Box>

                {stripeErrorMessage && <Alert severity="error">{stripeErrorMessage}</Alert>}
            </form>
        </Box>
    );
}
