import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RecommendationPublic, RecommendationBasic, RecommendationBrand } from '../../pages';
import roles from '../../hooks/roles';
import { Navigate } from 'react-router-dom';

const RecommendationRouter = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userTypeState, setUserTypeState] = useState(null);
    const { userType } = useSelector((state) => state.userReducer);

    useEffect(() => {
        if (userType) {
            setUserTypeState(userType);
        }
    }, [userType]);

    useEffect(() => {
        setIsLoading(false);
    }, [userTypeState, isLoading]);

    return (
        <>
            {!isLoading && (
                <>
                    {userTypeState === roles.BASIC_BRAND_USER ? (
                        <RecommendationBasic />
                    ) : userTypeState === roles.BRAND ? (
                        <Navigate to="/dashboard" />
                    ) : (
                        <RecommendationPublic />
                    )}
                </>
            )}
        </>
    );
};

export default RecommendationRouter;
