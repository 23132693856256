import { Container, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AppFrame from '../../hoc/AppFrame';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';

const useStyles = makeStyles((theme) => ({
    heading1: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'black',
        marginBottom: '24px',
    },
    heading2: {
        textTransform: 'uppercase',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'black',
        marginBottom: '8px',
        marginTop: '16px',
    },
    heading3: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'black',
        marginBottom: '8px',
        marginTop: '8px',
    },
    body: {
        fontSize: '14px',
        color: 'black',
        lineHeight: '1.5',
    },
}));

const Terms = () => {
    useAuthorization([roles.ADMIN, roles.BRAND, roles.BASIC_BRAND_USER, roles.PUBLIC]);
    const classes = useStyles();

    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: '80px',
                marginBottom: '80px',
            }}
        >
            <Typography className={classes.heading1}>TERMS AND CONDITIONS</Typography>

            <Typography className={classes.body}>
                For information about how Wineconx Gmbh collects, uses and shares your information,
                please review our{' '}
                <Link href="/privacy" underline="none">
                    Privacy Policy
                </Link>
                .
            </Typography>

            <Typography className={classes.heading2}>INTRODUCTION</Typography>
            <Typography className={classes.body}>
                Welcome to Wineconx Gmbh's influencer profile research product (the "Product").
                These terms and conditions (the "Agreement") govern your use of the Product. By
                using the Product, you agree to be bound by this Agreement. If you do not agree to
                be bound by this Agreement, please do not use the Product.
            </Typography>
            <Typography className={classes.heading2}>Use of the Product</Typography>
            <Typography className={classes.heading3}>2.1 Eligibility</Typography>
            <Typography className={classes.body}>
                The Product is intended for use by wineries and marketing agencies. You must be at
                least 18 years of age and legally authorized to enter into agreements in your
                jurisdiction to use the Product.
            </Typography>
            <Typography className={classes.heading3}>2.2 Scope of the Product</Typography>
            <Typography className={classes.body}>
                The Product currently offers influencer profile research on Instagram only. Wineconx
                Gmbh may expand the scope of the Product in the future to include other social media
                platforms.
            </Typography>
            <Typography className={classes.heading3}>2.3 Single Payment Options</Typography>
            <Typography className={classes.body}>
                The Product is available for purchase through single payment options only. You may
                purchase information on a single creator without registration.
            </Typography>
            <Typography className={classes.heading3}>2.4 Limitations on Use</Typography>
            <Typography className={classes.body}>
                You agree to use the Product only for lawful purposes and in accordance with this
                Agreement. Wineconx Gmbh reserves the right to limit or restrict your use of the
                Product at any time and for any reason.
            </Typography>
            <Typography className={classes.heading3}>2.5 No Subscription</Typography>
            <Typography className={classes.body}>
                The Product is not available through a subscription service.
            </Typography>
            <Typography className={classes.heading3}>2.6 Refunds</Typography>
            <Typography className={classes.body}>
                All purchases made through the Product are final and non-refundable.
            </Typography>
            <Typography className={classes.heading2}>INTELLECTUAL PROPERTY</Typography>
            <Typography className={classes.heading3}>3.1 Ownership</Typography>
            <Typography className={classes.body}>
                Wineconx Gmbh owns all rights, title and interest in and to the Product, including
                without limitation, the algorithm, the database, and the website. The Product offers
                public information, but the intelligence based on it is Wineconx Gmbh's property.
                This includes, but is not limited to, who we select, based on what principles, how
                we rate them.
            </Typography>
            <Typography className={classes.heading3}>3.2 Restrictions on Use</Typography>
            <Typography className={classes.body}>
                You agree not to use the Product in any way that would infringe upon the
                intellectual property rights of Wineconx Gmbh or any third party.
            </Typography>
            <Typography className={classes.heading2}>Limitation of Liability</Typography>
            <Typography className={classes.body}>
                To the maximum extent permitted by law, Wineconx Gmbh shall not be liable for any
                direct, indirect, incidental, special, consequential or exemplary damages, including
                but not limited to, damages for loss of profits, goodwill, use, data or other
                intangible losses arising out of or in connection with your use of the Product.
            </Typography>
            <Typography className={classes.heading2}>JURISDICTION</Typography>
            <Typography className={classes.body}>
                This Agreement shall be governed by and construed in accordance with the laws of
                Germany, without giving effect to any principles of conflicts of law. Any legal
                action arising out of or in connection with this Agreement shall be brought in the
                courts of Germany.
            </Typography>
            <Typography className={classes.heading2}>Modification of Terms</Typography>
            <Typography className={classes.body}>
                Wineconx Gmbh reserves the right to modify this Agreement at any time and for any
                reason. Modifications to this Agreement will be effective upon posting on the
                website.
            </Typography>
            <Typography className={classes.body}>
                If you have any questions or concerns regarding this Agreement, please contact us at{' '}
                <Link href="mailto:support@wineconx.com" underline="none">
                    support@wineconx.com
                </Link>
                .
            </Typography>
            <Typography className={classes.body}>
                By using the Product, you acknowledge that you have read and understand this
                Agreement and agree to be bound by its terms and conditions.
            </Typography>
        </Container>
    );
};

export default AppFrame(Terms);
