import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';
import AppFrame from '../../hoc/AppFrame';

const Logout = () => {
    const navigate = useNavigate();

    useAuthorization([
        roles.ADMIN,
        roles.BASIC_BRAND_USER,
        roles.BRAND,
        roles.PUBLIC,
        roles.BASIC_CREATOR_USER,
    ]);

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 1500);
    }, [navigate]);

    return (
        <Box
            sx={{
                margin: '25vh auto 0vh auto',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                width: '350px',
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    marginTop: (theme) => theme.NavBar.height,
                    marginBottom: '20px',
                    fontSize: '28px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    paddingBottom: '20px',
                }}
            >
                Logged out
                <br /> successfully
            </Typography>
            <Typography
                sx={{
                    marginBottom: '20px',
                    fontSize: '14px',
                    textAlign: 'center',
                    paddingBottom: '20px',
                }}
            >
                Redirecting to the landing page...
            </Typography>
        </Box>
    );
};

export default AppFrame(Logout);
