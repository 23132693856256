import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({ message, type, variant, open, onClose }) => {
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleClose = (event, reason) => {
        // console.log(`type: ${type}`);
        // console.log(`variant: ${variant}`);
        // console.log(`message: ${message}`);

        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
        onClose();
    };

    const severity = {
        success: 'success',
        created: 'success',
        exists: 'warning',
        info: 'info',
        error: 'error',
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={2500}
            onClose={handleClose}
            sx={{
                marginBottom: 3,
            }}
        >
            <Alert
                onClose={handleClose}
                severity={severity[type]}
                variant={variant ? variant : 'filled'}
                color="inherit"
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
