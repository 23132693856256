import { TextField } from '@mui/material';

export default ({ setKeyword, enterClick }) => {
    return (
        <TextField
            onChange={(e) => {
                setKeyword(e.target.value);
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    enterClick();
                }
            }}
            fullWidth
            name="search"
            label="Search"
            placeholder=""
            variant="outlined"
            sx={{
                width: '200px',
                padding: '9px',
                '& label': {
                    paddingTop: '8px',
                    paddingLeft: '8px',
                },

                '& .MuiOutlinedInput-root': {
                    height: '70px',
                    marginTop: '-7px',
                    marginLeft: '-10px',
                    borderRadius: '50px',
                    paddingLeft: '20px',
                    backgroundColor: 'white',

                    '&:hover fieldset': {
                        borderColor: 'black',
                    },
                },
            }}
        />
    );
};
