import { Stack, Chip, Tooltip } from '@mui/material';
import { green, teal, pink, blue, grey, blueGrey, brown } from '@mui/material/colors';

import {
    // DRAFT,
    OFFER_INVITE,
    OFFER,
    DECLINED_OFFER,
    WAITING_FOR_DISPATCH,
    PRODUCT_SENT,
    SHIPPING_ISSUE,
    ONGOING,
    CANCELLED_ONGOING,
    PROOF_UPLOADED,
    FULFILLED,
} from '../../constants/missionStates';

const plural = (count) => (count > 1 ? 's' : '');

const MissionCounter = (props) => {
    const { campaign } = props;

    const counter = campaign.missions.reduce(
        (acc, mission) => {
            const counterList = acc;
            switch (mission.status.currentStatus) {
                case CANCELLED_ONGOING:
                    counterList.CANCELLED_ONGOING_DECLINED_OFFER++;
                    break;
                case DECLINED_OFFER:
                    counterList.CANCELLED_ONGOING_DECLINED_OFFER++;
                    break;
                case FULFILLED:
                    counterList.FULFILLED++;
                    break;
                case OFFER:
                    counterList.CANCELLED_ONGOING_DECLINED_OFFER++;
                    break;
                case OFFER_INVITE:
                    counterList.CANCELLED_ONGOING_DECLINED_OFFER++;
                    break;
                case ONGOING:
                    counterList.ONGOING++;
                    break;
                case PRODUCT_SENT:
                    counterList.PRODUCT_SENT++;
                    break;
                case PROOF_UPLOADED:
                    counterList.PROOF_UPLOADED++;
                    break;
                case SHIPPING_ISSUE:
                    counterList.SHIPPING_ISSUE++;
                    break;
                case WAITING_FOR_DISPATCH:
                    counterList.WAITING_FOR_DISPATCH++;
                    break;
                default:
                    break;
            }
            return counterList;
        },
        {
            // DRAFT: 0,
            CANCELLED_ONGOING_DECLINED_OFFER: 0,
            FULFILLED: 0,
            //   OFFER: 0,
            ONGOING: 0,
            PRODUCT_SENT: 0,
            SHIPPING_ISSUE: 0,
            WAITING_FOR_DISPATCH: 0,
            PROOF_UPLOADED: 0,
        }
    );

    return (
        <Stack direction="row" spacing={1}>
            {counter.PRODUCT_SENT ? (
                <Tooltip
                    title={`${counter.PRODUCT_SENT} product${plural(
                        counter.PRODUCT_SENT
                    )} to be delivered soon`}
                    placement="top"
                >
                    <Chip
                        label={counter.PRODUCT_SENT}
                        sx={{ width: '40px', backgroundColor: brown[300] }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
            {counter.SHIPPING_ISSUE ? (
                <Tooltip
                    title={`${counter.SHIPPING_ISSUE} issue${plural(
                        counter.SHIPPING_ISSUE
                    )} on shipping`}
                    placement="top"
                >
                    <Chip
                        label={counter.SHIPPING_ISSUE}
                        sx={{ width: '40px', backgroundColor: grey[200] }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
            {counter.WAITING_FOR_DISPATCH ? (
                <Tooltip
                    title={`${counter.WAITING_FOR_DISPATCH} product${plural(
                        counter.WAITING_FOR_DISPATCH
                    )} to be seeded`}
                    placement="top"
                >
                    <Chip
                        label={counter.WAITING_FOR_DISPATCH}
                        sx={{ width: '40px', backgroundColor: teal['A700'] }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
            {/** {counter.OFFER ? (
                 <Tooltip
                     title={`${counter.OFFER} offer${plural(
                         counter.OFFER
                     )} are idle`}
                     placement="top"
                 >
                     <Chip
                         label={counter.OFFER}
                         sx={{
                             width: '40px',
                             backgroundColor: amber[300],
                             color: grey[900],
                         }}
                     />
                 </Tooltip>
             ) : (
                 ''
             )}
             */}
            {counter.ONGOING ? (
                <Tooltip title={`${counter.ONGOING} running`} placement="top">
                    <Chip
                        label={counter.ONGOING}
                        sx={{
                            width: '40px',
                            backgroundColor: blue[400],
                            color: blueGrey[50],
                        }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
            {counter.PROOF_UPLOADED ? (
                <Tooltip title={`${counter.PROOF_UPLOADED} waiting for review`} placement="top">
                    <Chip
                        label={counter.PROOF_UPLOADED}
                        sx={{
                            width: '40px',
                            backgroundColor: teal['A700'],
                            color: blueGrey[50],
                        }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
            {counter.FULFILLED ? (
                <Tooltip title={`${counter.FULFILLED} fulfilled`} placement="top">
                    <Chip
                        label={counter.FULFILLED}
                        sx={{
                            width: '40px',
                            backgroundColor: green[400],
                            color: blueGrey[50],
                        }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
            {counter.CANCELLED_ONGOING_DECLINED_OFFER ? (
                <Tooltip
                    title={`${counter.CANCELLED_ONGOING_DECLINED_OFFER} terminated`}
                    placement="top"
                >
                    <Chip
                        label={counter.CANCELLED_ONGOING_DECLINED_OFFER}
                        sx={{
                            width: '40px',
                            backgroundColor: pink[500],
                            color: blueGrey[50],
                        }}
                    />
                </Tooltip>
            ) : (
                ''
            )}
        </Stack>
    );
};

export default MissionCounter;
