import axios from 'axios';

import { getCurrentUserToken } from './';

export async function postCampaignRecommendation(campaignRecommendation) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaignRecommendation`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
        data: campaignRecommendation,
    });
}

export async function addLikedCreatorToCampaignRecommendation(campaignId, creatorId) {
    const userToken = getCurrentUserToken();
    try {
        const response = await axios({
            method: 'PATCH',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaignRecommendation/addLikedCreator`,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            data: {
                campaignId,
                creatorId,
            },
        });

        return response;
    } catch (error) {
        if (error.response.status === 304) {
            return {
                status: 304,
                message: error.message,
            };
        } else {
            console.error('error happened in calling API addLikedCreatorToCampaignRecommendation');
            return error;
        }
    }
}

export async function removeMyCreatorsList(campaignId) {
    const userToken = getCurrentUserToken();

    try {
        const response = await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaignRecommendation/removeList`,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            data: {
                campaignId,
            },
        });
        return response;
    } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 304) {
            return {
                status: 304,
                message: error.message,
            };
        } else {
            console.error('error happened in calling API removeMyCreatorsList');
            return error;
        }
    }
}

export async function removeLikedCreatorFromCampaignRecommendation(campaignId, creatorId) {
    const userToken = getCurrentUserToken();
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaignRecommendation/removeLikedCreator`,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            data: {
                campaignId,
                creatorId,
            },
        });

        return response;
    } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 304) {
            return {
                status: 304,
                message: error.message,
            };
        } else {
            console.error(
                'error happened in calling API removeLikedCreatorFromCampaignRecommendation'
            );
            return error;
        }
    }
}

export async function getSavedRecommendationsByOwnerId() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaignRecommendation/getSavedRecommendationsByOwnerId`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}
