import {
    SET_CAMPAIGN_DATA,
    RESET_CAMPAIGN_SETUP_DATA,
    SET_DEFAULT_COMPENSATION,
    SET_CAMPAIGN_MISSING_FIELDS,
    SET_DEFAULT_TASKS,
} from './types';

export const setCampaignData = (value) => ({
    type: SET_CAMPAIGN_DATA,
    payload: value,
});

export const setDefaultTasks = (value) => ({
    type: SET_DEFAULT_TASKS,
    payload: value,
});

export const setDefaultCompensation = (value) => ({
    type: SET_DEFAULT_COMPENSATION,
    payload: value,
});

export const setCampaignMissingFields = (value) => ({
    type: SET_CAMPAIGN_MISSING_FIELDS,
    payload: value,
});

export const resetCampaignSetupData = () => ({
    type: RESET_CAMPAIGN_SETUP_DATA,
});
