// useAuthorization.js
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import roles from './roles';

const useAuthorization = (allowedRoles) => {
    // console.log('🍔 [useAuthorization]');

    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer);

    const LOGIN = '/login';
    const DESCRIPTION = '/description';

    useEffect(() => {
        const { userType, isLoggedIn } = user;
        // console.log(`🔑 auth: ${JSON.stringify(user)}`);

        if (isLoggedIn) {
            if (allowedRoles.includes(userType)) {
                // console.log(`🔑 auth match: ${userType} `);
                return true;
            } else {
                // console.log(`🔑 auth not allowed in this page`);
                navigate(DESCRIPTION);
                return false;
            }
        } else {
            if (allowedRoles.includes(roles.PUBLIC)) {
                // console.log(`🔑 auth match: ${roles.PUBLIC} `);
                return true;
            } else {
                // console.log(`🔑 auth not logged in`);
                navigate(LOGIN);
                return false;
            }
        }
    }, [user, allowedRoles, navigate]);

    // console.log('🍔 [useAuthorization END]');
    return true;
};

export default useAuthorization;
