import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CircularProgress,
    Grid,
    Stack,
    Box,
    Typography,
    Button,
    Collapse,
    Paper,
    Checkbox,
    Container,
} from '@mui/material/';

import AppFrame from '../../hoc/AppFrame';
import CreatorCardsSearch from '../../components/CreatorCardsSearch';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';
import CreatorTypeBasedOnFollowersSelector from '../../components/DropdownSelector';
import TargetAudienceLocation from '../../components/DropdownSelector';
import { countriesList } from '../../utils/countriesAndStatesList';
import CampaignRegionSelector from '../../components/DropdownSelector';
import { setCampaignData } from '../../redux/campaignSetup/actions';
import { getCreators } from '../../services';

const MINIMUM_CREATORS_COUNT_BY_COUNTRY = 12;

const useIntersectionObserver = (ref, options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            //  observer.unobserve(ref.current);
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
};

const BrandSuperUserSearch = () => {
    useAuthorization([roles.BRAND]);

    // redux states
    const { campaign } = useSelector((state) => state.campaignSetupReducer);
    const { user } = useSelector((state) => state.userReducer);

    const dispatch = useDispatch();
    const ref = useRef(null);

    // local states
    const isMounted = useRef(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [moreOpen, setMoreOpen] = useState(false);
    const [creatorList, setCreatorList] = useState(null);
    const [pageCount, setPageCount] = useState(0);

    const followersClassDictionary = {
        'Nano (1K to 10K)': 'nano',
        'Micro (10K to 80K)': 'micro',
        'Macro (more than 80K)': 'macro',
    };

    const [creatorSearchFilterList, setCreatorSearchFilterList] = useState({
        increasing: 'increasing',
        creatorTypeBasedOnFollowers: campaign.creatorTypeBasedOnFollowers.map((followersClass) => {
            return followersClassDictionary[followersClass];
        }),
        campaignRegion: campaign.campaignRegion,
        targetAudienceLocation: campaign.targetAudienceLocation,

        ignoreLiked: campaign.ignoreLiked,
        ignoreDisliked: campaign.ignoreDisliked,
    });

    const isBottomVisible = useIntersectionObserver(ref, { threshold: 0 }, false);

    useEffect(() => {
        const append = async () => {
            if (isBottomVisible && creatorList !== null && creatorList.length > 0) {
                setIsLoading(true);
                await appendCreatorList();
                setIsLoading(false);
            }
        };
        append();
    }, [isBottomVisible]);

    // functions

    const setSelectedValue = (fieldName, newValue) => {
        // update state
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            [fieldName]: newValue,
        });

        dispatch(
            setCampaignData({
                ...campaign,
                [fieldName]: newValue,
            })
        );
    };

    const setDislikeFilter = async (isChecked) => {
        setSelectedValue('ignoreDisliked', isChecked);
    };

    const setLikeFilter = async (isChecked) => {
        setSelectedValue('ignoreLiked', isChecked);
    };
    const nextButtonTouched = false;

    async function newCreatorListLoad() {
        setPageCount(0);

        const newFilterSet = {
            ...creatorSearchFilterList,
            page: 0,
        };

        setCreatorSearchFilterList(newFilterSet);

        // find nano micro macro and replace the long chosen option with the short version
        const filterSetFixedWithProperCategories = {
            ...newFilterSet,
            creatorTypeBasedOnFollowers: newFilterSet.creatorTypeBasedOnFollowers.map(
                (followersClass) => {
                    return followersClassDictionary[followersClass];
                }
            ),
        };

        const creators = await getCreators(filterSetFixedWithProperCategories);
        if (isMounted.current) {
            setCreatorList(creators.data);
        }
    }

    const appendCreatorList = async () => {
        const page = pageCount + 1;
        const newFilterSet = {
            ...creatorSearchFilterList,
            page: page,
        };

        setCreatorSearchFilterList(newFilterSet);

        // find nano micro macro and replace the long chosen option with the short version
        const filterSetFixedWithProperCategories = {
            ...newFilterSet,
            creatorTypeBasedOnFollowers: newFilterSet.creatorTypeBasedOnFollowers.map(
                (followersClass) => {
                    return followersClassDictionary[followersClass];
                }
            ),
        };

        const creators = await getCreators(filterSetFixedWithProperCategories);
        if (creators && creators.data && creators.data.length > 0) {
            setCreatorList([...creatorList, ...creators.data]);
            setPageCount(page);
        }
    };

    const handleSubmit = async () => {
        setCreatorList(null);
        setIsLoading(true);
        await newCreatorListLoad();
        setIsLoading(false);
    };

    // useEffects

    // set isMobile
    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    // startup load
    useEffect(() => {
        isMounted.current = true;

        const loadData = async () => {
            await newCreatorListLoad();
            if (isMounted.current) {
                setIsLoading(false);
            }
        };

        loadData();

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Container
            sx={{
                marginTop: '120px',
                paddingBottom: '120px',
            }}
        >
            {/* selectors */}
            <Grid container columns={12} spacing={4}>
                <Grid item xs={12} md={6}>
                    <CreatorTypeBasedOnFollowersSelector
                        name="creatorTypeBasedOnFollowers"
                        fieldNameLabel="Creator amount of followers "
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaign.creatorTypeBasedOnFollowers}
                        nextButtonTouched={nextButtonTouched}
                        required={false}
                        multiple={true}
                        optionsLabels={[
                            'Nano (1K to 10K)',
                            'Micro (10K to 80K)',
                            'Macro (more than 80K)',
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TargetAudienceLocation
                        name="targetAudienceLocation"
                        fieldNameLabel="Location of the target audience"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaign.targetAudienceLocation}
                        nextButtonTouched={nextButtonTouched}
                        required={false}
                        optionsLabels={countriesList.reduce((countriesToDisplay, countryItem) => {
                            const modifiedCountriewsList = countriesToDisplay;
                            if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                modifiedCountriewsList.push(countryItem.country);
                            }
                            return modifiedCountriewsList;
                        }, [])}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CampaignRegionSelector
                        name="campaignRegion"
                        fieldNameLabel="Creator is based in"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaign.campaignRegion}
                        nextButtonTouched={nextButtonTouched}
                        required={false}
                        optionsLabels={countriesList.reduce((countriesToDisplay, countryItem) => {
                            const modifiedCountriewsList = countriesToDisplay;
                            if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                modifiedCountriewsList.push(countryItem.country);
                            }
                            return modifiedCountriewsList;
                        }, [])}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Button
                        onClick={() => {
                            setMoreOpen(!moreOpen);
                        }}
                    >
                        more
                    </Button>
                    <Collapse in={moreOpen}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            sx={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                paddingLeft: '3vw',
                                paddingRight: '3vw',
                                borderRadius: '30px',
                            }}
                        >
                            <Stack
                                direction="column"
                                sx={{
                                    color: '#666666',
                                }}
                            >
                                <span>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            label="hideDisliked"
                                            checked={campaign.ignoreDisliked}
                                            onChange={(e) => setDislikeFilter(e.target.checked)}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Hide disliked creators of earlier campaigns
                                        </Typography>
                                    </Stack>
                                </span>
                                <span>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            label="hideLiked"
                                            checked={campaign.ignoreLiked}
                                            onChange={(e) => setLikeFilter(e.target.checked)}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Hide liked creators of earlier campaigns
                                        </Typography>
                                    </Stack>
                                </span>
                            </Stack>
                        </Paper>
                    </Collapse>
                </Grid>
                <Grid item xs={6} md={6} />
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Typography
                        textAlign="right"
                        sx={{
                            position: 'absolute',
                            top: '20px',
                            right: '0px',
                            fontSize: '0.7rem',
                            color: '#999999',
                        }}
                    >
                        Fields marked with * are mandatory.
                    </Typography>
                </Grid>
            </Grid>
            <Button variant="contained" onClick={handleSubmit}>
                search
            </Button>

            {/* show data */}
            {!!creatorList && creatorList.length === 0 && (
                <Typography>No creators with these requirements</Typography>
            )}

            {!!creatorList && creatorList.length > 0 && (
                <Box>
                    <CreatorCardsSearch creatorList={creatorList} campaigns={user.campaigns} />
                </Box>
            )}

            {isLoading && (
                <Stack sx={{ alignItems: 'center', paddingTop: '48px' }}>
                    <CircularProgress />
                </Stack>
            )}

            <div ref={ref} style={{ width: '100%', height: '20px' }} />
        </Container>
    );
};

export default AppFrame(BrandSuperUserSearch);
