import React, { Fragment, useState } from 'react';

import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material/';

import CampaignRow from './CampaignRow';

const CampaignList = (props) => {
    const { brandCampaigns } = props;

    const campaignsByDate = brandCampaigns.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    return (
        <Grid container>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600 }} colSpan={2}>
                                Campaign
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Brand</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>title</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>goal</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>missions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaignsByDate.map((item, index) => (
                            <CampaignRow key={`ongoingcampaign_${index}`} campaign={item} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default CampaignList;
