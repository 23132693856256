import React, { useState, useEffect } from 'react';
import { Grid, Stack, Typography } from '@mui/material/';

import DescriptionFormPublic from '../../components/DescriptionFormPublic';
import AppFrame from '../../hoc/AppFrame';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const DescriptionPublic = () => {
    useAuthorization([roles.PUBLIC]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    return (
        <>
            <WelcomeText isMobile={isMobile} />
            <DescriptionFormPublic isMobile={isMobile} />
        </>
    );
};

const WelcomeText = ({ isMobile }) => {
    return (
        <>
            <Stack
                direction="column"
                sx={{
                    paddingTop: (theme) => theme.NavBar.height,
                    height: '100%',
                }}
            >
                {isMobile ? (
                    <Stack direction="column" alignItems="center" height="100%" paddingBottom={1}>
                        <Typography
                            width="100%"
                            variant="h2"
                            paddingTop={0}
                            paddingBottom={3}
                            paddingRight={3}
                            color="secondary"
                            sx={{
                                textAlign: 'center',
                                fontFamily: 'PlusJakartaSans',
                                textTransform: 'uppercase',
                                fontSize: 20,
                                fontWeight: 650,
                                wordSpacing: '1vw',
                            }}
                        >
                            Effortlessly Find the Right Wine Influencers for your social media
                            campaigns
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography
                                    variant="h4"
                                    paddingBottom={1}
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                >
                                    Tell us about your brand
                                </Typography>
                                <Typography
                                    variant="body"
                                    sx={{
                                        fontSize: 12,
                                    }}
                                >
                                    Answer a few questions and WineConX AI instantly selects the
                                    best Influencers for your social media campaigns.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography
                                    variant="h4"
                                    paddingBottom={1}
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                >
                                    No contracts needed
                                </Typography>
                                <Typography
                                    variant="body"
                                    sx={{
                                        fontSize: 12,
                                    }}
                                >
                                    $99 per Influencer. Simply pay with your credit card for only
                                    the influencers you find promising.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                ) : (
                    <Stack direction="column">
                        <Typography
                            width="100%"
                            variant="h2"
                            paddingBottom={3}
                            paddingRight={3}
                            color="secondary"
                            sx={{
                                fontFamily: 'PlusJakartaSans',
                                textTransform: 'uppercase',
                                fontSize: 30,
                                fontWeight: 650,
                                wordSpacing: '1vw',
                            }}
                        >
                            Effortlessly Find the Right Wine Influencers for your social media
                            campaigns
                        </Typography>
                        <Stack direction="row" alignItems="center" height="100%" paddingBottom={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="h4"
                                        paddingBottom={1}
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Tell us about your brand
                                    </Typography>
                                    <Typography variant="body">
                                        Answer a few questions and WineConX AI instantly selects the
                                        best Influencers for your social media campaigns.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="h4"
                                        paddingBottom={1}
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        No contracts needed
                                    </Typography>
                                    <Typography variant="body">
                                        $99 per Influencer. Simply pay with your credit card for
                                        only the influencers you find promising.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default AppFrame(DescriptionPublic);
