const wineRegionLabelDictionary = {
    Spain: 'spanishWine',
    France: 'frenchWine',
    Catalonia: 'catalanWine',
    Tuscany: 'tuscanaWine',
    'Napa Valley': 'napaValleyWine',
};

const wineProducingRegions = [
    'Albania',
    'Berat (Albania)',
    'Korça (Albania)',
    'Lezhë (Albania)',
    'Shkodër (Albania)',
    'Tirana (Albania)',
    'Argentina',
    'Mendoza (Argentina)',
    'San Juan (Argentina)',
    'La Rioja (Argentina)',
    'Salta (Argentina)',
    'Catamarca (Argentina)',
    'Neuquén (Argentina)',
    'Río Negro (Argentina)',
    'Buenos Aires (Argentina)',
    'Australia',
    'Barossa Valley (Australia)',
    'Margaret River (Australia)',
    'Hunter Valley (Australia)',
    'Yarra Valley (Australia)',
    'Adelaide Hills (Australia)',
    'McLaren Vale (Australia)',
    'Clare Valley (Australia)',
    'Coonawarra (Australia)',
    'Heathcote (Australia)',
    'Austria',
    'Burgenland (Austria)',
    'Kamptal (Austria)',
    'Kremstal (Austria)',
    'Wachau (Austria)',
    'Weinviertel (Austria)',
    'Brazil',
    'Vale dos Vinhedos (Brazil)',
    'Serra Gaúcha (Brazil)',
    'Bulgaria',
    'Thracian Lowland (Bulgaria)',
    'Black Sea Region (Bulgaria)',
    'Danubian Plain (Bulgaria)',
    'Canada',
    'Niagara Peninsula (Canada)',
    'Okanagan Valley (Canada)',
    'Similkameen Valley (Canada)',
    'Prince Edward County (Canada)',
    'Chile',
    'Maipo Valley (Chile)',
    'Colchagua Valley (Chile)',
    'Casablanca Valley (Chile)',
    'Limarí Valley (Chile)',
    'Aconcagua Valley (Chile)',
    'Curicó Valley (Chile)',
    'Rapel Valley (Chile)',
    'Cachapoal Valley (Chile)',
    'Elqui Valley (Chile)',
    'China',
    'Ningxia (China)',
    'Xinjiang (China)',
    'Shandong (China)',
    'Croatia',
    'Istria (Croatia)',
    'Slavonia (Croatia)',
    'Dalmatia (Croatia)',
    'Cyprus',
    'Commandaria (Cyprus)',
    'Czech Republic',
    'Moravia (Czech Republic)',
    'France',
    'Bordeaux (France)',
    'Burgundy (France)',
    'Champagne (France)',
    'Rhône Valley (France)',
    'Loire Valley (France)',
    'Alsace (France)',
    'Languedoc-Roussillon (France)',
    'Provence (France)',
    'Corsica (France)',
    'Jura (France)',
    'Savoie (France)',
    'Georgia',
    'Kakheti (Georgia)',
    'Kartli (Georgia)',
    'Racha-Lechkhumi and Kvemo Svaneti (Georgia)',
    'Imereti (Georgia)',
    'Germany',
    'Mosel (Germany)',
    'Rheingau (Germany)',
    'Pfalz (Germany)',
    'Baden (Germany)',
    'Nahe (Germany)',
    'Rheinhessen (Germany)',
    'Greece',
    'Macedonia (Greece)',
    'Crete (Greece)',
    'Santorini (Greece)',
    'Attica (Greece)',
    'Peloponnese (Greece)',
    'Hungary',
    'Tokaj (Hungary)',
    'Villány (Hungary)',
    'Eger (Hungary)',
    'India',
    'Nashik (India)',
    'Nandi Hills (India)',
    'Nilgiri Hills (India)',
    'Karnataka (India)',
    'Israel',
    'Galilee (Israel)',
    'Judean Hills (Israel)',
    'Shomron (Israel)',
    'Italy',
    'Tuscany (Italy)',
    'Piedmont (Italy)',
    'Veneto (Italy)',
    'Sicily (Italy)',
    'Trentino-Alto Adige (Italy)',
    'Umbria (Italy)',
    'Friuli-Venezia Giulia (Italy)',
    'Marche (Italy)',
    'Abruzzo (Italy)',
    'Campania (Italy)',
    'Sardinia (Italy)',
    'Puglia (Italy)',
    'Basilicata (Italy)',
    'Calabria (Italy)',
    'Molise (Italy)',
    'Japan',
    'Yamanashi (Japan)',
    'Nagano (Japan)',
    'Hokkaido (Japan)',
    'Lebanon',
    'Bekaa Valley (Lebanon)',
    'Mexico',
    'Baja California (Mexico)',
    'Coahuila (Mexico)',
    'Queretaro (Mexico)',
    'Zacatecas (Mexico)',
    'Moldova',
    'Codru (Moldova)',
    'Stefan Voda (Moldova)',
    'Valul lui Traian (Moldova)',
    'Morocco',
    'Meknes (Morocco)',
    'Benslimane (Morocco)',
    'New Zealand',
    'Marlborough (New Zealand)',
    "Hawke's Bay (New Zealand)",
    'Central Otago (New Zealand)',
    'Wairarapa (New Zealand)',
    'Portugal',
    'Douro Valley (Portugal)',
    'Alentejo (Portugal)',
    'Vinho Verde (Portugal)',
    'Dão (Portugal)',
    'Bairrada (Portugal)',
    'Colares (Portugal)',
    'Madeira (Portugal)',
    'Porto (Portugal)',
    'Romania',
    'Transylvania (Romania)',
    'Muntenia (Romania)',
    'Dobrogea (Romania)',
    'Serbia',
    'Župa (Serbia)',
    'Fruška Gora (Serbia)',
    'Negotin (Serbia)',
    'Slovakia',
    'Tokaj (Slovakia)',
    'Slovenia',
    'Primorska (Slovenia)',
    'Podravje (Slovenia)',
    'South Africa',
    'Stellenbosch (South Africa)',
    'Paarl (South Africa)',
    'Swartland (South Africa)',
    'Franschhoek (South Africa)',
    'Robertson (South Africa)',
    'Wellington (South Africa)',
    'Spain',
    'Rioja (Spain)',
    'Ribera del Duero (Spain)',
    'Priorat (Spain)',
    'Penedès (Spain)',
    'Rías Baixas (Spain)',
    'Jerez (Spain)',
    'Navarra (Spain)',
    'Somontano (Spain)',
    'Switzerland',
    'Valais (Switzerland)',
    'Vaud (Switzerland)',
    'Geneva (Switzerland)',
    'Ticino (Switzerland)',
    'Turkey',
    'Thrace (Turkey)',
    'Anatolia (Turkey)',
    'Cappadocia (Turkey)',
    'Ukraine',
    'Crimea (Ukraine)',
    'Carpathian Ruthenia (Ukraine)',
    'United Kingdom',
    'Kent (United Kingdom)',
    'Sussex (United Kingdom)',
    'Cornwall (United Kingdom)',
    'Wales (United Kingdom)',
    'United States',
    'California (United States)',
    'Oregon (United States)',
    'Washington (United States)',
    'New York (United States)',
    'Virginia (United States)',
    'Texas (United States)',
    'Pennsylvania (United States)',
    'Ohio (United States)',
    'Missouri (United States)',
    'Michigan (United States)',
    'Arizona (United States)',
    'Colorado (United States)',
    'Idaho (United States)',
    'New Jersey (United States)',
    'North Carolina (United States)',
    'Uruguay',
    'Canelones (Uruguay)',
    'Montevideo (Uruguay)',
    'San José (Uruguay)',
    'Venezuela',
    'Lara (Venezuela)',
    'Sucre (Venezuela)',
    'Táchira (Venezuela)',
];

const labelDictionary = {
    wineExpert: 'Wine Expert',
    wineLover: 'Wine Lover',
    foodie: 'Foodie',
    lifestyle: 'Lifestyle',
    spanishWine: 'Spanish wine',
    frenchWine: 'French wine',
    catalanWine: 'Catalan wine',
    tuscanaWine: 'Tuscana wine',
    napaValleyWine: 'Napa Valley wine',
    sommelier: 'Sommelier',
    fineWine: 'Fine wine',
};

const getLabelName = (labelAlias) => {
    return labelDictionary[labelAlias];
};

export { wineRegionLabelDictionary, wineProducingRegions, getLabelName };
