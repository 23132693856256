import { createTheme } from '@mui/material/styles';
import { blue, purple, lime } from '@mui/material/colors';

/*
    #37c936 #5fc2bd #25D9CD #007a87 #549F93
    #062273 #0652e3 
    #2c2929 #fdfdfe #777777
    #dd1840 #e85f7b #900020 #FF3456
    #ffdd95
    purple, blue, orange
*/

const theme = createTheme({
    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            },
        },
    },
    typography: {
        h1: { fontStyle: 'regular', fontSize: '2.5em' },
        h2: { fontStyle: 'regular', fontSize: '1.5em' },
        h3: { fontStyle: 'regular', fontSize: '2em' },
        h4: { fontStyle: 'regular', fontSize: '1.2em' },
        h5: { fontStyle: 'regular' },
        h6: { fontStyle: 'regular' },
        subtitle1: { fontStyle: 'regular' },
        subtitle2: { fontStyle: 'regular' },
        body1: { fontStyle: 'regular' },
        body2: { fontStyle: 'regular' },
        button: { fontStyle: 'regular' },
        caption: { fontStyle: 'regular' },
        overline: { fontStyle: 'regular' },

        fontFamily: [
            '-apple-system',
            'PlusJakartaSans',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    Card: {
        width: 300,
        height: 300,
        margin: 'auto',
    },
    Media: {
        height: '100%',
        width: '100%',
    },

    palette: {
        type: 'light',
        primary: {
            main: '#de4564',
        },
        secondary: {
            main: '#062273',
            grey: '#777777',
        },
        highlight: {
            main: 'orange',
        },
        background: {
            main: 'white',
            highlight: 'orange',
        },
        loveSelection: {
            liked: '#37c936',
            disliked: '#f03030',
            void: '#777777',
        },

        error: {
            main: '#e85f7b',
            light: '#e85f7b',
            dark: '#e85f7b',
            contrastText: 'white',
        },
        success: {
            main: blue[700],
            light: blue[700],
            dark: blue[700],
            contrastText: 'white',
        },
        warning: {
            main: purple[500],
            light: purple[300],
            dark: purple[700],
            contrastText: 'white',
        },
        info: lime,
    },
    NavBar: {
        height: '85px',
        paddingRight: '8px',
        paddingLeft: '2vw',
        Logo: {
            paddingTop: '1vh',
        },
    },
    AppFrame: {
        paddingLeft: '24px',
        paddingRight: '20px',
    },
    SideBar: {
        width: '240px',
    },
    SectionTools: {
        height: '12vh',
        width: '84vw',
        paddingBottom: '3vh',
        paddingRight: '6vh',
        paddingLeft: '3vh',
        zIndex: '100',
    },
});

const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            50: '#37c936',
            100: '#37c936',
            200: '#37c936',
            300: '#37c936',
            400: '#37c936',
            500: '#37c936',
            600: '#37c936',
            700: '#37c936',
            800: '#37c936',
            900: '#37c936',
            A100: '#37c936',
            A200: '#37c936',
            A400: '#37c936',
            A700: '#37c936',
            contrastDefaultColor: 'light',
        },
    },
});

export default theme;
