import React from 'react';
import { useSelector } from 'react-redux';

import { DescriptionPublic, BasicDescription, DescriptionBasicCreator } from '../../pages';
import roles from '../../hooks/roles';
import { Navigate } from 'react-router-dom';

const DescriptionRouter = () => {
    const { userType } = useSelector((state) => state.userReducer);

    return (
        <>
            {userType === roles.BASIC_BRAND_USER ? (
                <BasicDescription />
            ) : userType === roles.BASIC_CREATOR_USER ? (
                <DescriptionBasicCreator />
            ) : userType === roles.BRAND ? (
                <Navigate to="/dashboard" />
            ) : (
                <DescriptionPublic />
            )}
        </>
    );
};

export default DescriptionRouter;
