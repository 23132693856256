import initialState from './state';

import { SET_VOUCHER_COUNT } from './types';

const voucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VOUCHER_COUNT:
            const newState = {
                voucherCount: action.payload,
            };
            return newState;
        default:
            return state;
    }
};

export default voucherReducer;
