import { useState } from 'react';

import { IconButton, Stack, Box } from '@mui/material';
import { ThumbDown, ThumbUp, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';

const LikeDislikeSelector = ({
    creatorItem,
    handleReactionButton,
    selectedLike,
    setSelectedLike,
}) => {
    const handleLikeSelectionClick = () => {
        setSelectedLike(1);
        handleReactionButton({
            _id: creatorItem._id,
            reaction: 1,
            creatorData: creatorItem,
        });
    };
    const handleDislikeSelectionClick = () => {
        setSelectedLike(0);
        handleReactionButton({
            _id: creatorItem._id,
            reaction: 0,
            creatorData: creatorItem,
        });
    };

    return (
        <Box
            sx={{
                width: '90px',
                height: '50px',
                backgroundColor: selectedLike === null ? '#efefef' : '#fff',
                boxShadow: selectedLike === null ? '5px 5px 20px 10px rgba(0,0,0,0.1)' : '',
                position: 'absolute',
                left: '-8px',
                top: '-7px',
                borderRadius: '10px 0px 25px 0px',
            }}
        >
            <IconButton
                aria-label="add"
                onClick={handleLikeSelectionClick}
                sx={{
                    color: selectedLike === 1 ? 'loveSelection.liked' : 'loveSelection.void',
                    position: 'absolute',
                    top: 5,
                    left: 5,
                }}
            >
                <Stack
                    borderRadius="50%"
                    sx={{
                        backgroundColor: 'none',
                    }}
                >
                    {selectedLike === 1 ? <ThumbUp /> : <ThumbUpAlt />}
                </Stack>
            </IconButton>
            <IconButton
                aria-label="add"
                onClick={handleDislikeSelectionClick}
                sx={{
                    color: selectedLike === 0 ? 'loveSelection.disliked' : 'loveSelection.void',
                    position: 'absolute',
                    top: 5,
                    left: 40,
                }}
            >
                <Stack
                    borderRadius="50%"
                    sx={{
                        backgroundColor: 'none',
                    }}
                >
                    {selectedLike === 0 ? <ThumbDown /> : <ThumbDownAlt />}
                </Stack>
            </IconButton>
        </Box>
    );
};

export default LikeDislikeSelector;
