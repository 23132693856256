import axios from 'axios';

import { getCurrentUserToken } from './';

export async function getUserCreatorMissions() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/creatormissions`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function claimMissionDiscountCode() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/claimcreatormission`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function sendCreatorMissionPostUrl({ postUrl }) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/completecreatormission`,
        data: {
            postUrl,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function applyToDeals() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/dealapplication`,
        data: {},
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getApplicationDeals() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/dealapplication`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getCreatorData() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/userprofile`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function updateCreatorProfile(userData) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/updateProfile`,
        data: userData,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function updateCreatorProfilePicture(userData) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/updateProfilePicture`,
        data: userData,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getCreatorMissions() {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/missions`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function acceptOffer(missionId) {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/accept`,
        data: {
            missionId,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function cancelMission(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/cancel`,
        data: {
            missionId,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function declineOffer(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        data: {
            missionId,
        },
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/decline`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function receiveShipment(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        data: {
            missionId,
        },
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/receiveShipment`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getPortfolio() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/portfolio`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function saveTaskProofUrl(taskId, instagramProofUrl) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/saveTaskProof`,
        data: {
            taskId: taskId,
            instagramProofUrl: instagramProofUrl,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function finishFulfilmentProof(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/delivery`,
        data: {
            missionId: missionId,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function shippingIssue(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/creator/mission/shippingIssue`,
        data: {
            missionId: missionId,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}
