import axios from 'axios';

import { getCurrentUserToken } from './';

export async function getCampaignsAll() {
    // const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/admin/getCampaigns`,
        // headers: {
        //   Authorization: `Bearer ${userToken}`,
        // },
    });
}

export async function getCreatorsFullData(creatorSearchFilterList) {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/admin/getCreators`,
        params: creatorSearchFilterList,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function concealDuplicateCreator(creatorId, duplicate) {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/admin/concealDuplicateCreator`,
        data: {
            _id: creatorId,
            duplicate: duplicate,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function concealIrrelevantCreator(creatorId, irrelevant) {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/admin/concealIrrelevantCreator`,
        data: {
            _id: creatorId,
            irrelevant: irrelevant,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function concealUtfIssueCreator(creatorId, utfIssue) {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/admin/concealUtfIssueCreator`,
        data: {
            _id: creatorId,
            utfIssue: utfIssue,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}
