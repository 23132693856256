import React from 'react';

import { Grid } from '@mui/material/';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import CreatorCardStepRecommendation from './CreatorCardStepRecommendation';

const CreatorCardsStepRecommendation = ({
    creatorList,
    updatePurchasedCreators,
    handleReactionButton,
    handleAddButton,
    handleRemoveButton,
    nextButtonTouched,
    isThisADemo,
    isThisMyCreatorsPage,
}) => {
    return (
        <Grid
            container
            spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
            columns={{ xs: 12, sm: 12, md: 18, lg: 20, xl: 20 }}
            paddingTop={(theme) => theme.NavBar.height}
            paddingBottom={2}
        >
            {creatorList &&
                creatorList.map((creatorItem, index) => {
                    return (
                        <CreatorCardStepRecommendation
                            key={`cc_${index}`}
                            creatorItem={creatorItem}
                            updatePurchasedCreators={updatePurchasedCreators}
                            handleReactionButton={handleReactionButton}
                            handleAddButton={handleAddButton}
                            handleRemoveButton={handleRemoveButton}
                            nextButtonTouched={nextButtonTouched}
                            isThisADemo={isThisADemo}
                            isThisMyCreatorsPage={isThisMyCreatorsPage}
                        />
                    );
                })}
        </Grid>
    );
};

export default CreatorCardsStepRecommendation;
