import { Grid } from '@mui/material';
import CardItem from './CardItem';

const CreatorCardsSearch = ({ creatorList, campaigns }) => {
    return (
        <Grid
            container
            spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
            columns={{ xs: 12, sm: 12, md: 18, lg: 20, xl: 20 }}
            paddingTop={(theme) => theme.NavBar.height}
            paddingBottom={2}
        >
            {creatorList &&
                creatorList.map((c, ci) => {
                    return <CardItem key={`cc_${ci}`} creatorItem={c} campaigns={campaigns} />;
                })}
        </Grid>
    );
};

export default CreatorCardsSearch;
