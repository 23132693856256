export const SET_LOGIN_STATE = 'SET_LOGIN_STATE';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const RESET_USER_STATE = 'RESET_USER_STATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_BRAND_PROFILE = 'SET_BRAND_PROFILE';
export const SET_CREATOR_PROFILE = 'SET_CREATOR_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_PUBLIC_USERVIEW = 'SET_PUBLIC_USERVIEW';
export const SET_CAMPAIGN_NAME_LIST = 'SET_CAMPAIGN_NAME_LIST';
