import { useState, forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Box,
    Autocomplete,
    FormControl,
    InputLabel,
    Chip,
    Dialog,
    Checkbox,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Slide,
    TextField,
} from '@mui/material/';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MultiSelectResponsive = ({
    isMobile,
    setChosenOptions,
    defaultValue,
    nextButtonTouched,
    required,
    name,
    fieldNameLabel,
    optionsLabels,
    multiple,
}) => {
    return isMobile ? (
        <MobileMultiSelect
            options={optionsLabels}
            label={fieldNameLabel}
            selectedValues={defaultValue}
            onChange={(value) => setChosenOptions(name, value)}
        />
    ) : (
        <MultiSelectDesktop
            setChosenOptions={setChosenOptions}
            defaultValue={defaultValue}
            nextButtonTouched={nextButtonTouched}
            required={required}
            name={name}
            fieldNameLabel={fieldNameLabel}
            optionsLabels={optionsLabels}
            multiple={multiple}
        />
    );
};

const MultiSelectDesktop = ({
    setChosenOptions,
    defaultValue,
    nextButtonTouched,
    required,
    name,
    fieldNameLabel,
    optionsLabels,
    multiple,
}) => {
    const optionsList = optionsLabels.map((label) => {
        return { label: label };
    });
    const handleOptionSelect = (event, value) => {
        if (value === null) {
            setChosenOptions(name, multiple ? [] : '');
        } else {
            setChosenOptions(name, multiple ? value.map((option) => option.label) : value.label);
        }
    };

    return (
        <Autocomplete
            disabled={false}
            onChange={handleOptionSelect}
            multiple={multiple}
            id={name}
            sx={{
                width: '100%',
            }}
            options={optionsList}
            autoHighlight
            value={
                multiple
                    ? defaultValue
                        ? defaultValue.map((value) => {
                              return { label: value };
                          })
                        : []
                    : defaultValue
                    ? { label: defaultValue }
                    : null
            }
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    name={name}
                >
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    required={required}
                    error={required && nextButtonTouched && !defaultValue}
                    helperText={
                        required &&
                        nextButtonTouched &&
                        !defaultValue &&
                        `${fieldNameLabel} cannot be empty`
                    }
                    variant="standard"
                    sx={{
                        '& .css-1sxvwpc-MuiFormLabel-root-MuiInputLabel-root': {
                            marginTop: '',
                            marginLeft: '',
                        },

                        '& .MuiOutlinedInput-input': {
                            marginLeft: '',
                        },
                        '& .MuiAutocomplete-popper': {
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            width: '',
                            margin: '0px',
                            transform: 'translate3d(1500px, 600.5px, 0px)',
                        },
                        '& .MuiOutlinedInput-root': {
                            marginTop: '',
                            marginLeft: '',
                            borderRadius: '50px',
                            backgroundColor: 'white',
                            height: '',
                            '&:hover fieldset': {
                                borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary',
                            },
                        },
                        width: '100%',
                        borderRadius: '25px',
                        marginTop: '',
                    }}
                    {...params}
                    label={fieldNameLabel}
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MobileMultiSelect = ({ options, label, selectedValues, onChange }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelectChange = (value) => {
        const selectedIndex = selectedValues.indexOf(value);
        let newSelectedValues = [...selectedValues];

        if (selectedIndex === -1) {
            newSelectedValues.push(value);
        } else {
            newSelectedValues.splice(selectedIndex, 1);
        }

        onChange(newSelectedValues);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                height: '48px',
                verticalAlign: 'bottom',
            }}
        >
            <TextField
                fullWidth
                onClick={handleOpen}
                variant="standard"
                value="Creator follower base"
                sx={{
                    '& input': {
                        color: 'rgba(0, 0, 0, 0.6)',
                    },
                    position: 'absolute',
                    bottom: '0px',
                }}
                inputProps={{
                    readOnly: true,
                }}
            />

            <Box
                sx={{
                    position: 'absolute',
                    right: '0px',
                    verticalAlign: 'bottom',
                    paddingTop: '8px',
                }}
            >
                {selectedValues.length === 3 ? (
                    <Chip key={'All'} label={'All'} onDelete={() => onChange([])} />
                ) : (
                    selectedValues.map((value) => {
                        const followersClassDictionary = {
                            'Nano (1K to 10K)': 'nano',
                            'Micro (10K to 80K)': 'micro',
                            'Macro (more than 80K)': 'macro',
                        };

                        return (
                            <Chip
                                key={followersClassDictionary[value]}
                                label={followersClassDictionary[value]}
                                onDelete={() => handleSelectChange(value)}
                            />
                        );
                    })
                )}
            </Box>
            <Dialog
                TransitionComponent={Transition}
                fullScreen
                sx={{
                    pt: '56px',
                }}
                open={open}
                onClose={handleClose}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    // onClick={() => {}}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <FormControl sx={{ width: '100%' }}>
                        {/* <InputLabel>Choose values</InputLabel> */}
                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {options.map((option) => (
                                <ListItem
                                    key={option}
                                    button
                                    onClick={() => handleSelectChange(option)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedValues.indexOf(option) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={option} />
                                </ListItem>
                            ))}
                        </div>
                    </FormControl>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default MultiSelectResponsive;
