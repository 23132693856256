import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Box, Typography } from '@mui/material/';

import AppFrame from '../../hoc/AppFrame';
import { setCampaignData } from '../../redux/campaignSetup/actions';
import { countriesList } from '../../utils/countriesAndStatesList';
import ResponsiveSelect from '../../components/ResponsiveSelect';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';

const MINIMUM_CREATORS_COUNT_BY_COUNTRY = 12;

const LandingForm = () => {
    useAuthorization([roles.PUBLIC]);

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const { campaign: campaignData } = useSelector((state) => state.campaignSetupReducer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setSelectedValue = (fieldName, newValue) => {
        setSelectedLocation(newValue);
        dispatch(setCampaignData({ [fieldName]: newValue }));
        navigate('/recommendation');
    };

    useEffect(() => {
        if (selectedLocation && campaignData.targetAudienceLocation) {
            navigate('/recommendation');
        }
    }, [campaignData.targetAudienceLocation, selectedLocation]);

    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    return (
        <>
            <Grid
                container
                columns={12}
                spacing={isMobile ? 5 : 10}
                paddingBottom={12}
                paddingTop={isMobile ? 6 : 20}
            >
                <Grid
                    className="effortlessly_introduction"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    paddingRight={isMobile ? 0 : 6}
                >
                    <Stack direction="column" alignItems={isMobile && 'center'}>
                        <Typography
                            width="100%"
                            variant="h2"
                            paddingTop={2}
                            paddingBottom={isMobile ? 2 : 3}
                            color="secondary"
                            sx={{
                                fontFamily: 'PlusJakartaSans',
                                textTransform: 'uppercase',
                                fontSize: isMobile ? 25 : 40,
                                fontWeight: 650,
                                wordSpacing: '1vw',
                            }}
                            textAlign={isMobile ? 'center' : 'left'}
                        >
                            Find the Right Wine Influencers for you
                        </Typography>

                        <Typography
                            variant="body"
                            paddingBottom={isMobile ? 4 : 8}
                            textAlign={isMobile ? 'center' : 'left'}
                        >
                            Forget about hours of scrolling through Instagram. {!isMobile && <br />}
                            Just answer a few questions. {!isMobile && <br />}
                            We’ll present you with a curated list of wine influencers.
                        </Typography>
                        <Typography
                            variant="h4"
                            paddingBottom={isMobile ? 0 : 2}
                            color="secondary"
                            textAlign={isMobile ? 'center' : 'left'}
                            sx={{
                                fontFamily: 'PlusJakartaSans',
                                fontSize: isMobile ? 16 : 25,
                                fontWeight: 600,
                                wordSpacing: '0.35vw',
                            }}
                        >
                            Get your brand in front of the audience you want to reach.
                        </Typography>
                        <Box width="250px">
                            <ResponsiveSelect
                                name="targetAudienceLocation"
                                label="Select followers location"
                                defaultValue={''}
                                options={countriesList.reduce((countriesToDisplay, countryItem) => {
                                    const modifiedCountriewsList = countriesToDisplay;
                                    if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                        modifiedCountriewsList.push(countryItem.country);
                                    }
                                    return modifiedCountriewsList;
                                }, [])}
                                isMobile={isMobile}
                                enableNone={false}
                                enableSearch={true}
                                setChosenOptions={setSelectedValue}
                            />
                        </Box>
                    </Stack>
                </Grid>

                <Grid className="snapshot_image" item xs={12} sm={12} md={12} lg={6}>
                    {/* show image from s3 bucket */}
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/landing6ItemsSnapshot.png"
                        alt="snapshot"
                        width="100%"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AppFrame(LandingForm);
