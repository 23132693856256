const usStateList = [
    { country: 'Alabama, USA', count: 12 },
    { country: 'Alaska, USA', count: 3 },
    { country: 'Arizona, USA', count: 76 },
    { country: 'Arkansas, USA', count: 9 },
    { country: 'California, USA', count: 1822 },
    { country: 'Colorado, USA', count: 127 },
    { country: 'Connecticut, USA', count: 38 },
    { country: 'Delaware, USA', count: 4 },
    { country: 'Florida, USA', count: 363 },
    { country: 'Georgia, USA', count: 93 },
    { country: 'Hawaii, USA', count: 19 },
    { country: 'Idaho, USA', count: 33 },
    { country: 'Illinois, USA', count: 130 },
    { country: 'Indiana, USA', count: 16 },
    { country: 'Iowa, USA', count: 7 },
    { country: 'Kansas, USA', count: 12 },
    { country: 'Kentucky, USA', count: 11 },
    { country: 'Louisiana, USA', count: 37 },
    { country: 'Maine, USA', count: 14 },
    { country: 'Maryland, USA', count: 41 },
    { country: 'Massachusetts, USA', count: 123 },
    { country: 'Michigan, USA', count: 72 },
    { country: 'Minnesota, USA', count: 42 },
    { country: 'Mississippi, USA', count: 13 },
    { country: 'Missouri, USA', count: 64 },
    { country: 'Montana, USA', count: 10 },
    { country: 'Nevada, USA', count: 45 },
    { country: 'New Hampshire, USA', count: 5 },
    { country: 'New Jersey, USA', count: 51 },
    { country: 'New Mexico, USA', count: 13 },
    { country: 'New York, USA', count: 634 },
    { country: 'North Carolina, USA', count: 108 },
    { country: 'Ohio, USA', count: 64 },
    { country: 'Oklahoma, USA', count: 4 },
    { country: 'Oregon, USA', count: 224 },
    { country: 'Pennsylvania, USA', count: 81 },
    { country: 'Rhode Island, USA', count: 19 },
    { country: 'South Carolina, USA', count: 46 },
    { country: 'Tennessee, USA', count: 48 },
    { country: 'Texas, USA', count: 394 },
    { country: 'Utah, USA', count: 29 },
    { country: 'Vermont, USA', count: 14 },
    { country: 'Virginia, USA', count: 137 },
    { country: 'Washington, USA', count: 281 },
    { country: 'West Virginia, USA', count: 4 },
    { country: 'Wisconsin, USA', count: 36 },
    { country: 'Wyoming, USA', count: 5 },
];

export const countriesList = [
    { country: 'Albania', count: 2 },
    { country: 'Algeria', count: 2 },
    { country: 'Andorra', count: 3 },
    { country: 'Antigua and Barbuda', count: 1 },
    { country: 'Argentina', count: 550 },
    { country: 'Armenia', count: 22 },
    { country: 'Aruba', count: 3 },
    { country: 'Australia', count: 929 },
    { country: 'Austria', count: 132 },
    { country: 'Azerbaijan', count: 1 },
    { country: 'Bahrain', count: 1 },
    { country: 'Barbados', count: 1 },
    { country: 'Belarus', count: 2 },
    { country: 'Belgium', count: 104 },
    { country: 'Belize', count: 1 },
    { country: 'Benin', count: 1 },
    { country: 'Bermuda', count: 2 },
    { country: 'Bosnia and Herzegovina', count: 4 },
    { country: 'Brazil', count: 595 },
    { country: 'Bulgaria', count: 29 },
    { country: 'Burundi', count: 1 },
    { country: 'Cambodia', count: 3 },
    { country: 'Cameroon', count: 1 },
    { country: 'Canada', count: 825 },
    { country: 'Chad', count: 1 },
    { country: 'Chile', count: 257 },
    { country: 'China', count: 40 },
    { country: 'Colombia', count: 40 },
    { country: 'Costa Rica', count: 19 },
    { country: 'Croatia', count: 73 },
    { country: 'Cuba', count: 12 },
    { country: 'Curaçao', count: 4 },
    { country: 'Cyprus', count: 30 },
    { country: 'Czechia', count: 23 },
    { country: 'Denmark', count: 57 },
    { country: 'Ecuador', count: 30 },
    { country: 'Egypt', count: 7 },
    { country: 'El Salvador', count: 2 },
    { country: 'Estonia', count: 2 },
    { country: 'Ethiopia', count: 3 },
    { country: 'Fiji', count: 2 },
    { country: 'Finland', count: 36 },
    { country: 'France', count: 1215 },
    { country: 'French Polynesia', count: 2 },
    { country: 'Georgia', count: 55 },
    { country: 'Germany', count: 918 },
    { country: 'Ghana', count: 2 },
    { country: 'Greece', count: 205 },
    { country: 'Grenada', count: 1 },
    { country: 'Guatemala', count: 13 },
    { country: 'Guernsey', count: 1 },
    { country: 'Guinea', count: 1 },
    { country: 'Guyana', count: 1 },
    { country: 'Haiti', count: 2 },
    { country: 'Honduras', count: 1 },
    { country: 'Hong Kong', count: 124 },
    { country: 'Hungary', count: 35 },
    { country: 'Iceland', count: 5 },
    { country: 'India', count: 120 },
    { country: 'Indonesia', count: 18 },
    { country: 'Ireland', count: 105 },
    { country: 'Israel', count: 56 },
    { country: 'Italy', count: 2543 },
    { country: 'Jamaica', count: 12 },
    { country: 'Japan', count: 131 },
    { country: 'Jersey', count: 8 },
    { country: 'Jordan', count: 5 },
    { country: 'Kazakhstan', count: 7 },
    { country: 'Kenya', count: 15 },
    { country: 'Latvia', count: 7 },
    { country: 'Lebanon', count: 26 },
    { country: 'Lesotho', count: 1 },
    { country: 'Liberia', count: 2 },
    { country: 'Lithuania', count: 2 },
    { country: 'Luxembourg', count: 6 },
    { country: 'Madagascar', count: 2 },
    { country: 'Malawi', count: 1 },
    { country: 'Malaysia', count: 10 },
    { country: 'Maldives', count: 12 },
    { country: 'Malta', count: 11 },
    { country: 'Mauritius', count: 5 },
    { country: 'Mexico', count: 273 },
    { country: 'Monaco', count: 6 },
    { country: 'Montenegro', count: 8 },
    { country: 'Morocco', count: 8 },
    { country: 'Mozambique', count: 1 },
    { country: 'Myanmar', count: 1 },
    { country: 'Namibia', count: 3 },
    { country: 'Nepal', count: 3 },
    { country: 'New Zealand', count: 142 },
    { country: 'Nigeria', count: 14 },
    { country: 'Norway', count: 57 },
    { country: 'Oman', count: 2 },
    { country: 'Pakistan', count: 3 },
    { country: 'Panama', count: 24 },
    { country: 'Paraguay', count: 3 },
    { country: 'Peru', count: 37 },
    { country: 'Poland', count: 44 },
    { country: 'Portugal', count: 366 },
    { country: 'Puerto Rico', count: 45 },
    { country: 'Qatar', count: 15 },
    { country: 'Romania', count: 53 },
    { country: 'Réunion', count: 1 },
    { country: 'San Marino', count: 1 },
    { country: 'Saudi Arabia', count: 1 },
    { country: 'Serbia', count: 48 },
    { country: 'Seychelles', count: 1 },
    { country: 'Sierra Leone', count: 1 },
    { country: 'Singapore', count: 61 },
    { country: 'Slovakia', count: 16 },
    { country: 'Slovenia', count: 54 },
    { country: 'South Africa', count: 373 },
    { country: 'Spain', count: 929 },
    { country: 'Sri Lanka', count: 1 },
    { country: 'Sweden', count: 92 },
    { country: 'Switzerland', count: 140 },
    { country: 'Taiwan', count: 6 },
    { country: 'Thailand', count: 42 },
    { country: 'Tunisia', count: 1 },
    { country: 'Turkey', count: 151 },
    { country: 'UK', count: 1568 },
    { country: 'Uganda', count: 1 },
    { country: 'Ukraine', count: 26 },
    { country: 'Uruguay', count: 35 },
    { country: 'USA', count: 223 },
    ...usStateList,
    { country: 'Uzbekistan', count: 1 },
    { country: 'Yemen', count: 1 },
    { country: 'Zambia', count: 1 },
    { country: 'Zimbabwe', count: 2 },
];
