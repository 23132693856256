// process.env destructuring

const {
    REACT_APP_NODE_ENV,
    REACT_APP_FB_API_KEY,
    REACT_APP_FB_AUTH_DOMAIN,
    REACT_APP_FB_PROJECT_ID,
    REACT_APP_FB_STORAGE_BUCKET,
    REACT_APP_FB_MESSAGING_SENDER_ID,
    REACT_APP_FB_APP_ID,
    REACT_APP_FB_MESUREMENT_ID,
    REACT_APP_WINECONX_API_URL,
} = process.env;

const CONFIG = {
    development: {
        url: {
            server: REACT_APP_WINECONX_API_URL,
        },
        firebaseConfig: {
            apiKey: REACT_APP_FB_API_KEY,
            authDomain: REACT_APP_FB_AUTH_DOMAIN,
            projectId: REACT_APP_FB_PROJECT_ID,
            storageBucket: REACT_APP_FB_STORAGE_BUCKET,
            messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
            appId: REACT_APP_FB_APP_ID,
            measurementId: REACT_APP_FB_MESUREMENT_ID,
        },
    },
    production: {
        url: {
            server: REACT_APP_WINECONX_API_URL,
        },
        firebaseConfig: {
            apiKey: REACT_APP_FB_API_KEY,
            authDomain: REACT_APP_FB_AUTH_DOMAIN,
            projectId: REACT_APP_FB_PROJECT_ID,
            storageBucket: REACT_APP_FB_STORAGE_BUCKET,
            messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
            appId: REACT_APP_FB_APP_ID,
            measurementId: REACT_APP_FB_MESUREMENT_ID,
        },
    },
    stage: {
        url: {
            server: REACT_APP_WINECONX_API_URL,
        },
        firebaseConfig: {
            apiKey: REACT_APP_FB_API_KEY,
            authDomain: REACT_APP_FB_AUTH_DOMAIN,
            projectId: REACT_APP_FB_PROJECT_ID,
            storageBucket: REACT_APP_FB_STORAGE_BUCKET,
            messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
            appId: REACT_APP_FB_APP_ID,
            measurementId: REACT_APP_FB_MESUREMENT_ID,
        },
    },
    test: {
        url: {
            server: REACT_APP_WINECONX_API_URL,
        },
        firebaseConfig: {
            apiKey: REACT_APP_FB_API_KEY,
            authDomain: REACT_APP_FB_AUTH_DOMAIN,
            projectId: REACT_APP_FB_PROJECT_ID,
            storageBucket: REACT_APP_FB_STORAGE_BUCKET,
            messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
            appId: REACT_APP_FB_APP_ID,
            measurementId: REACT_APP_FB_MESUREMENT_ID,
        },
    },
};

export const config = CONFIG[REACT_APP_NODE_ENV];
