import React, { useState, useEffect } from 'react';
import { Typography, Stack, Slider, Box } from '@mui/material';

function calculateValue(value) {
    return value ** 1;
}

function slideValueFormat(value) {
    let postfixValue;
    let postfixedText;

    if (value === 500000) return 'no limit';

    if (value === 0) {
        postfixedText = '0';
    } else if (value < 1000000) {
        postfixValue = Math.round(value / 100) / 10;
        postfixedText = `${postfixValue} k`;
    } else {
        postfixValue = Math.round(value / 100000) / 10;
        postfixedText = `${postfixValue} m`;
    }

    return postfixedText;
}

export default function BetterRangeSlider({ setMinMax }) {
    const maxValue = 500000;
    const minValue = 1000;
    const minDistance = 1000;
    const step = 1000;
    const startVal1 = 1000;
    const startVal2 = maxValue; // const startVal2 = 40000;
    const endVal1 = 300;
    const endVal2 = 500;

    const [minMaxPair, setMinMaxPair] = useState([startVal1, startVal2]);

    const handleChange = (event, newValuePair, activeThumb) => {
        if (
            activeThumb === 0 &&
            newValuePair[1] === maxValue &&
            !(newValuePair[0] <= maxValue - minDistance)
        ) {
            setMinMax([maxValue - minDistance, null]);

            setMinMaxPair([maxValue - minDistance, maxValue]);
        } else {
            if (newValuePair[1] === maxValue) {
                setMinMax([newValuePair[0], null]);
            } else {
                setMinMax(newValuePair);
            }
            setMinMaxPair(newValuePair);
        }
    };

    return (
        <Stack
            height="70px"
            alignItems="center"
            direction="row"
            paddingLeft={4}
            paddingRight={4}
            paddingTop={2}
            paddingBottom={2}
            backgroundColor="white"
            sx={{
                marginBottom: '9px',
                marginRight: '9px',
                maxWidth: '900px',
                border: '1.5px solid #dbdbdc',
                borderRadius: '50px',
            }}
        >
            <Typography width="90px" marginRight="10px" fontSize={14} fontWeight={800}>
                {slideValueFormat(minMaxPair[0])}
            </Typography>

            <Slider
                width="50px"
                sx={{
                    color: '#ff4b5c',
                    height: 3,
                    padding: '13px 0',
                    '& .MuiSlider-thumb': {
                        height: 23,
                        width: 23,
                        backgroundColor: '#fff',
                        border: '3px solid currentColor',
                        '&:hover': {
                            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                        },
                        '& .airbnb-bar': {
                            height: 9,
                            width: 1,
                            backgroundColor: 'currentColor',
                            marginLeft: 1,
                            marginRight: 1,
                        },
                    },
                    '& .MuiSlider-track': {
                        height: 1.5,
                    },
                    '& .MuiSlider-rail': {
                        color: '#d8d8d8',
                        opacity: 1,
                        height: 3,
                    },
                }}
                min={minValue}
                max={maxValue}
                step={step}
                scale={calculateValue}
                getAriaLabel={() => 'Temperature range'}
                value={minMaxPair}
                onChange={handleChange}
                // valueLabelDisplay="on"
                valueLabelDisplay="auto"
                getAriaValueText={slideValueFormat}
                valueLabelFormat={slideValueFormat}
            />
            <Typography marginLeft="20px" width="110px" fontSize={14} fontWeight={800}>
                {slideValueFormat(minMaxPair[1])}
            </Typography>
        </Stack>
    );
}
