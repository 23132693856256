import {
    SET_CAMPAIGN_NAME_LIST,
    SET_LOGIN_STATE,
    SET_USER_DATA,
    SET_USER_TYPE,
    RESET_USER_STATE,
    SET_USER_PROFILE,
    SET_BRAND_PROFILE,
    SET_CREATOR_PROFILE,
    SET_LOADING_STATE,
    SET_PUBLIC_USERVIEW,
} from './types';

export const setPublicUserView = () => ({
    type: SET_PUBLIC_USERVIEW,
});

export const setLoadingState = (value) => ({
    type: SET_LOADING_STATE,
    payload: value,
});

export const setLoginState = (value) => ({
    type: SET_LOGIN_STATE,
    payload: value,
});

export const setUserType = (value) => ({
    type: SET_USER_TYPE,
    payload: value,
});

export const resetUserState = () => ({
    type: RESET_USER_STATE,
});

export const setUserData = (value) => ({
    type: SET_USER_DATA,
    payload: value,
});

export const setCampaignNameList = (value) => ({
    type: SET_CAMPAIGN_NAME_LIST,
    payload: value,
});

export const setUserProfile = (value) => ({
    type: SET_USER_PROFILE,
    payload: value,
});

export const setBrandProfile = (value) => ({
    type: SET_BRAND_PROFILE,
    payload: value,
});

export const setCreatorProfile = (value) => ({
    type: SET_CREATOR_PROFILE,
    payload: value,
});
