import { useState, forwardRef } from 'react';

import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Slide,
    TextField,
} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';

import DropdownSelector from '../DropdownSelector';

const ResponsiveSelect = ({
    name,
    label,
    defaultValue,
    options,
    isMobile,
    enableNone,
    enableSearch,
    setChosenOptions,
}) => {
    return isMobile ? (
        <MobileSelectorDialog
            defaultValue={defaultValue}
            name={name}
            label={label}
            options={options}
            enableNone={enableNone}
            enableSearch={enableSearch}
            setChosenOptions={setChosenOptions}
        />
    ) : (
        <DropdownSelector
            name={name}
            fieldNameLabel={label}
            setChosenOptions={setChosenOptions}
            defaultValue={defaultValue}
            nextButtonTouched={() => {}}
            variant="standard"
            required={false}
            multiple={false}
            optionsLabels={options}
        />
    );
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MobileSelectorDialog({
    name,
    label,
    options,
    enableNone,
    enableSearch,
    setChosenOptions,
}) {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [searchText, setSearchText] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelection = (chosenValue) => {
        setSelectedValue(chosenValue);
        setChosenOptions(name, chosenValue);
        handleClose();
    };

    const filteredOptions = enableSearch
        ? options.filter((optionValue) =>
              optionValue.toLowerCase().includes(searchText.toLowerCase())
          )
        : options;

    return (
        <div>
            <TextField
                onClick={handleClickOpen}
                variant="standard"
                label={selectedValue === '' ? label : ''}
                value={selectedValue}
                fullWidth
                InputProps={{
                    readOnly: true,
                }}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                sx={{
                    pt: '56px',
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        zIndex: 1,
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {!enableSearch && <DialogTitle>{label}</DialogTitle>}

                <DialogContent>
                    {enableSearch && (
                        <TextField
                            variant="standard"
                            label={label}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                        />
                    )}
                    <List>
                        {enableNone && (
                            <ListItem
                                sx={{
                                    paddingLeft: 1,
                                    backgroundColor: (theme) =>
                                        '' === selectedValue
                                            ? theme.palette.grey[200]
                                            : 'transparent',
                                }}
                                button
                                key={'no_value_selected'}
                                onClick={(event, value) => handleSelection('')}
                            >
                                <CloseIcon
                                    sx={{
                                        marginRight: 1,
                                    }}
                                />
                                <em>None</em>
                            </ListItem>
                        )}

                        {filteredOptions.map((optionValue) => (
                            <ListItem
                                sx={{
                                    backgroundColor: (theme) =>
                                        optionValue === selectedValue
                                            ? theme.palette.grey[200]
                                            : 'transparent',
                                }}
                                key={optionValue}
                                onClick={(event, value) => {
                                    handleSelection(optionValue);
                                }}
                            >
                                <ListItemText primary={optionValue} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ResponsiveSelect;
