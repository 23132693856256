import initialState from './state';

import {
    SET_CAMPAIGN_NAME_LIST,
    SET_LOADING_STATE,
    SET_LOGIN_STATE,
    SET_USER_TYPE,
    RESET_USER_STATE,
    SET_USER_DATA,
    SET_USER_PROFILE,
    SET_BRAND_PROFILE,
    SET_CREATOR_PROFILE,
    SET_PUBLIC_USERVIEW,
} from './types';

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PUBLIC_USERVIEW:
            const publicUserState = {
                user: null,
                username: state.username,
                isLoggedIn: false,
                userType: null, // options defined in roles.js
                profileImage: '',
                loading: false,
            };
            return publicUserState;
        case SET_LOADING_STATE:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_BRAND_PROFILE:
            return {
                ...state,
                user: {
                    ...state.user,
                    brand: {
                        ...state.user.brand,
                        brandName: action.payload.brandName,
                        website: action.payload.website,
                        phone: action.payload.phone,
                        address: action.payload.address,
                    },
                },
            };
        case SET_CREATOR_PROFILE:
            return {
                ...state,
                user: {
                    ...state.user,
                    creator: {
                        ...state.user.creator,
                        profileImage: action.payload.profileImage,
                        address: action.payload.address,
                        website: action.payload.website,
                        instagramUsername: action.payload.instagramUsername,
                    },
                },
            };

        case RESET_USER_STATE:
            const resetState = {
                user: null,
                username: state.username,
                isLoggedIn: false,
                userType: null, // options defined in roles.js
                profileImage: '',
                loading: state.loading,
            };
            return resetState;
        case SET_LOGIN_STATE:
            return {
                ...state,
                ...action.payload,
            };
        case SET_USER_DATA:
            const userData = {
                ...state,
                user: action.payload,
            };
            return userData;
        case SET_CAMPAIGN_NAME_LIST:
            return {
                ...state,
                user: {
                    ...state.user,
                    campaigns: action.payload,
                },
            };
        case SET_USER_PROFILE:
            const userProfile = {
                ...state,
                user: {
                    ...state.user,
                    personal: {
                        ...state.user.personal,
                        address: action.payload.address,
                        phone: action.payload.phone,
                    },
                },
            };
            return userProfile;
        case SET_USER_TYPE:
            const newState = {
                ...state,
                userType: action.payload,
            };
            return newState;
        default:
            return state;
    }
};

export default userReducer;
