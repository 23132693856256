import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
    applyActionCode,
    confirmPasswordReset,
} from 'firebase/auth';

export async function verifyUserByOobCode(oobCode) {
    const auth = getAuth();
    return applyActionCode(auth, oobCode);
}

export async function registerNewUserInFirebase(email, password) {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password);
}

export function logInFirebase(email, password) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
}

export function logOutFirebase() {
    const auth = getAuth();
    signOut(auth)
        .then(() => {
            return true;
        })
        .catch((error) => {
            console.error(`Sign out failed: ${error}`);
            return false;
        });
}

export async function authenticationObserver(callback) {
    const auth = getAuth();
    // gets the User and provides it to the callback as parameter
    return onAuthStateChanged(auth, callback);
}

export function getCurrentUserToken() {
    const auth = getAuth();
    // console.log(`auth.currentUser: ${JSON.stringify(auth.currentUser)}`);
    if (auth.currentUser === null) {
        return null;
    }
    return auth.currentUser.accessToken;
}

export function sendEmailVerificationFirebase() {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser);
}

export async function sendPasswordResetEmailFirebase(emailToReset) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, emailToReset);
}

export async function confirmPasswordResetFirebase(oobCode, newPassword) {
    const auth = getAuth();
    const response = await confirmPasswordReset(auth, oobCode, newPassword);
    return response;
}
