import { Backdrop, CircularProgress } from '@mui/material';

const WaitLoading = (props) => {
    // console.log(
    //     `         🌈[WaitLoading] ${props.isLoading ? 'Wait' : 'Done'}`
    // );
    const { isLoading } = props;

    return (
        isLoading && (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    );
};

export default WaitLoading;
