import initialState from './state';

import {
    SET_CAMPAIGN_DATA,
    SET_DEFAULT_COMPENSATION,
    SET_DEFAULT_TASKS,
    SET_CAMPAIGN_MISSING_FIELDS,
    RESET_CAMPAIGN_SETUP_DATA,
} from './types';

const campaignSetupReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CAMPAIGN_DATA:
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    ...action.payload,
                },
            };
        case SET_DEFAULT_TASKS:
            return {
                ...state,
                tasks: action.payload,
            };

        case SET_DEFAULT_COMPENSATION:
            return {
                ...state,
                compensation: action.payload,
            };
        case SET_CAMPAIGN_MISSING_FIELDS:
            return {
                ...state,
                campaignMissingFields: action.payload,
            };
        case RESET_CAMPAIGN_SETUP_DATA:
            return {
                ...initialState,
                campaign: {
                    ...initialState.campaign,
                    reactionsToRecommendedCreators: [],
                },
            };
        default:
            return state;
    }
};

export default campaignSetupReducer;
