import { Stack, Typography } from '@mui/material';

import AppFrame from '../../hoc/AppFrame';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';
import { getPurchasedCreatorsList } from '../../services/apiBrand';
import { useEffect, useState } from 'react';
import CreatorCardsStepRecommendation from '../../components/CreatorCards/CreatorCardsStepRecommendations';
import CreatorCardsMobile from '../../components/CreatorCardsMobile/CreatorCardsMobile';

const PurchasedCreators = () => {
    useAuthorization([roles.BASIC_BRAND_USER]);
    const [purchasedCreatorsList, setPurchasedCreatorsList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    useEffect(() => {
        const loadPurchasedCreators = async () => {
            const purchasedCreatorsList = await getPurchasedCreatorsList();
            setPurchasedCreatorsList(purchasedCreatorsList);
        };
        loadPurchasedCreators();
    }, []);

    return (
        <Stack
            direction="column"
            sx={{
                paddingTop: (theme) => theme.NavBar.height,
                height: '100%',
            }}
        >
            {purchasedCreatorsList && !isMobile && (
                <CreatorCardsStepRecommendation
                    creatorList={purchasedCreatorsList}
                    isThisADemo={true}
                    isThisMyCreatorsPage={true}
                />
            )}
            {purchasedCreatorsList && isMobile && (
                <CreatorCardsMobile
                    creatorList={purchasedCreatorsList}
                    isThisADemo={false}
                    isThisMyCreatorsPage={true}
                />
            )}
        </Stack>
    );
};

export default AppFrame(PurchasedCreators);
