import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, CircularProgress, Box } from '@mui/material/';

import Button from '../../components/Button';
import AppFrame from '../../hoc/AppFrame';

import { wineRegionLabelDictionary } from '../../utils/labelDictionary';
import { getDemoCreators, getOnePurchasedCreatorData } from '../../services';
import { setCampaignData } from '../../redux/campaignSetup/actions';
import CreatorCardsMobile from '../../components/CreatorCardsMobile/CreatorCardsMobile';
import CreatorCardsStepRecommendations from '../../components/CreatorCards/CreatorCardsStepRecommendations';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const paginationLimit = 24;
const isThisADemo = true;

const RecommendationBrand = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useAuthorization([roles.BRAND]);

    const { campaign: campaignData } = useSelector((state) => state.campaignSetupReducer);
    const { guestSessionIdentifier } = useSelector((state) => state.guestUserReducer);

    const [isMobile, setIsMobile] = useState(false);
    const [creatorSearchFilterList, setCreatorSearchFilterList] = useState({
        increasing: 'increasing',
        engagementRates: campaignData.engagementRates,
        creatorTypeBasedOnContent: campaignData.creatorTypeBasedOnContent,
        creatorTypeBasedOnFollowers: campaignData.creatorTypeBasedOnFollowers.map(
            (followersClass) => {
                const followersClassDictionary = {
                    'Nano (1K to 10K)': 'nano',
                    'Micro (10K to 80K)': 'micro',
                    'Macro (more than 80K)': 'macro',
                };
                return followersClassDictionary[followersClass];
            }
        ),
        typeOfWine: campaignData.typeOfWine,
        campaignRegion: campaignData.campaignRegion,
        targetAudienceLocation: campaignData.targetAudienceLocation,

        labelNames: (() => {
            const labelSelections = [];
            if (campaignData.foodieLabelSelector === 'yes') {
                labelSelections.push('foodie');
            }
            if (campaignData.fineWineLabelSelector === 'yes') {
                labelSelections.push('fineWine');
            }
            if (campaignData.lifestyleLabelSelector === 'yes') {
                labelSelections.push('lifestyle');
            }
            if (campaignData.wineLoverLabelSelector === 'yes') {
                labelSelections.push('wineLover');
            }
            if (campaignData.wineExpertLabelSelector === 'yes') {
                labelSelections.push('wineExpert');
            }
            if (campaignData.sommelierLabelSelector === 'yes') {
                labelSelections.push('sommelier');
            }
            if (campaignData.wineRegionLabelSelector !== '') {
                labelSelections.push(
                    wineRegionLabelDictionary[campaignData.wineRegionLabelSelector]
                );
            }

            return labelSelections;
        })(),
        ignoreLiked: campaignData.ignoreLiked,
        ignoreDisliked: campaignData.ignoreDisliked,
    });
    const [creatorList, setCreatorList] = useState(null);
    const [isDisplayed, setIsDisplayed] = useState(false);

    const updatePurchasedCreators = async (paymentIntentId, purchasedCreatorId) => {
        const purchasedCreatorData = await getOnePurchasedCreatorData(
            paymentIntentId,
            purchasedCreatorId
        );
        const purchasedCreator = purchasedCreatorData.data;

        const paidCreatorIndex = creatorList.findIndex((c) => c._id === purchasedCreator._id);
        // replace item in creatorList
        let newCreatorList = [...creatorList];
        newCreatorList[paidCreatorIndex] = purchasedCreator;

        setCreatorList(newCreatorList);
    };

    const newCreatorListLoad = async () => {
        const newFilterSet = {
            ...creatorSearchFilterList,
            page: 0,
            limit: paginationLimit,
        };
        setCreatorSearchFilterList(newFilterSet);

        const creators = await getDemoCreators({
            guestSessionIdentifier: guestSessionIdentifier,
            brandName: campaignData.brandName,
            productName: campaignData.productName,
            retailPrice: campaignData.retailPrice,
            wineProducingRegions: campaignData.wineProducingRegions,
            ...newFilterSet,
        });

        //setActualAmountOfRecommendedCreators(creators.data.length);
        setCreatorList(creators.data);

        if (campaignData.reactionsToRecommendedCreators.length > 0) {
            const queryResultCreatorArray = creators.data;
            const actualReactions = campaignData.reactionsToRecommendedCreators;

            const intersection = actualReactions.filter((reactionItem) =>
                queryResultCreatorArray.some(
                    (newCreatorItem) => reactionItem._id === newCreatorItem._id
                )
            );

            dispatch(
                setCampaignData({
                    ...campaignData,
                    reactionsToRecommendedCreators: intersection,
                })
            );
        }
    };

    const handleRefineSearch = () => {
        navigate('/description');
    };

    useEffect(() => {
        if (!!creatorList && creatorList.length === 0) {
            setIsDisplayed(true);
        }
    }, [creatorList]);

    useEffect(() => {
        newCreatorListLoad();
    }, []);

    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    paddingTop: '1vh',
                    paddingBottom: '1vh',
                    paddingLeft: (theme) => theme.AppFrame.paddingLeft,
                    top: (theme) => (isMobile ? '45px' : theme.NavBar.height),
                    left: 0,
                    zIndex: 100,
                }}
            >
                <Button variant="contained" onClick={handleRefineSearch}>
                    Refine search citeria
                </Button>
            </Box>

            <Stack paddingBottom={10} paddingTop={12}>
                <Stack>
                    {!!creatorList && creatorList.length === 0 && isDisplayed && (
                        <Typography
                            align="center"
                            sx={{
                                fontSize: '20px',
                            }}
                        >
                            No creators with these requirements
                        </Typography>
                    )}
                    {!creatorList && !isDisplayed && (
                        <Stack sx={{ alignItems: 'center', paddingTop: '40vh' }}>
                            <CircularProgress />
                        </Stack>
                    )}

                    {!isThisADemo && (
                        <Typography
                            sx={{
                                marginBottom: '30px',
                                marginLeft: '30px',
                            }}
                        >
                            Please like or dislike the recommended creators to save the good ones
                            for your campaign
                        </Typography>
                    )}
                    {isMobile ? (
                        <CreatorCardsMobile
                            creatorList={creatorList}
                            updatePurchasedCreators={updatePurchasedCreators}
                            isThisADemo={isThisADemo}
                        />
                    ) : (
                        <CreatorCardsStepRecommendations
                            creatorList={creatorList}
                            updatePurchasedCreators={updatePurchasedCreators}
                            handleReactionButton={() => {}}
                            handleAddButton={() => {}}
                            handleRemoveButton={() => {}}
                            isThisADemo={isThisADemo}
                        />
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default AppFrame(RecommendationBrand);
