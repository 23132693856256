import React, { useState, useEffect, useRef, Fragment } from 'react';

import { Box, Grid, Stack } from '@mui/material/';
import SearchIcon from '@mui/icons-material/Search';

import BetterRangeSlider from './BetterRangeSlider';
import Button from '../Button';
import CheckboxButton from '../Button/CheckboxButton';
import InspectCreatorCard from './InspectCreatorCard';
import SearchTextField from './SearchTextField';
import SortButton from '../Button/SortButton';
import UglyRegionSelector from './UglyRegionSelector';
import UglyLabelSelector from './UglyLabelSelector';

import {
    getCreatorsFullData,
    concealIrrelevantCreator,
    concealUtfIssueCreator,
    concealDuplicateCreator,
} from '../../services';

// TODO use only one constant
const paginationLimit = 24;
let currentLoadedPage = 0;
let allCreatorsLoaded = false;

const useIntersectionObserver = (ref, options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            //  observer.unobserve(ref.current);
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
};

/*
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
        const measurement = {
          top: entry.boundingClientRect.top,
          bottom: entry.boundingClientRect.bottom,
        };
        console.log(measurement);
        observer.unobserve(entry.target); //<-- call unobserve here
        });
      });
    const sections = document.querySelectorAll(`section#dark`)
    sections.forEach((section) => observer.observe(section));
    return () => {
       observer.disconnect();
    };
  }, []);
*/

const CreatorList = ({
    updateCreatorUtfIssueData,
    updateCreatorConcealData,
    updateDuplicateData,
    creatorList,
    isInDashBoard,
}) =>
    creatorList.map((creatorItem, index) => {
        return (
            <InspectCreatorCard
                updateCreatorConcealData={updateCreatorConcealData}
                updateCreatorUtfIssueData={updateCreatorUtfIssueData}
                updateDuplicateData={updateDuplicateData}
                key={`cc_${index}`}
                creatorItem={creatorItem}
                index={index}
                isInDashBoard={isInDashBoard}
            />
        );
    });

const CreatorCards = (props) => {
    const { isInDashBoard } = props;

    const [creatorSearchFilterList, setCreatorSearchFilterList] = useState({
        increasing: 'increasing',
        minFer: '1000',
        // maxFer: '40000',
    });
    const [creatorList, setCreatorList] = useState(null);
    const [isLoadingCreators, setIsLoadingCreators] = useState(true);
    const [sortDecreasing, setSortDecreasing] = useState(true);
    const [irrelevanceState, setIrrelevanceState] = useState(null);
    const [utfIssueState, setUtfIssueState] = useState(null);
    const [duplicateState, setDuplicateState] = useState(null);

    const ref = useRef(null);

    const handleRelevanceButtonClick = () => {
        if (irrelevanceState) {
            setIrrelevanceState(false);
            setIrrelevanceFilter(false);
        } else if (irrelevanceState === false) {
            setIrrelevanceState(null);
            setIrrelevanceFilter(null);
        } else {
            setIrrelevanceState(true);
            setIrrelevanceFilter(true);
        }
    };

    const handleUtfIssueButtonClick = () => {
        if (utfIssueState) {
            setUtfIssueState(false);
            setUtfIssueFilter(false);
        } else if (utfIssueState === false) {
            setUtfIssueState(null);
            setUtfIssueFilter(null);
        } else {
            setUtfIssueState(true);
            setUtfIssueFilter(true);
        }
    };

    const handleDuplicateButtonClick = () => {
        if (duplicateState) {
            setDuplicateState(false);
            setDuplicateFilter(false);
        } else if (duplicateState === false) {
            setDuplicateState(null);
            setDuplicateFilter(null);
        } else {
            setDuplicateState(true);
            setDuplicateFilter(true);
        }
    };

    const updateCreatorIrrelevanceData = async (creatorId, irrelevant) => {
        await concealIrrelevantCreator(creatorId, irrelevant);
    };

    const updateCreatorUtfIssueData = async (creatorId, utfIssue) => {
        await concealUtfIssueCreator(creatorId, utfIssue);
    };

    const updateDuplicateData = async (creatorId, duplicate) => {
        await concealDuplicateCreator(creatorId, duplicate);
    };

    const newCreatorListLoad = async () => {
        setCreatorList([]);
        setIsLoadingCreators(true);
        const newFilterSet = {
            ...creatorSearchFilterList,
            page: 0,
            limit: paginationLimit,
        };
        setCreatorSearchFilterList(newFilterSet);

        const creators = await getCreatorsFullData(newFilterSet);
        currentLoadedPage = 0;
        allCreatorsLoaded = false;
        setCreatorList(creators.data);
    };

    const appendCreatorList = async () => {
        if (!allCreatorsLoaded) {
            let newFilterSet;
            const nextPage = currentLoadedPage + 1;
            newFilterSet = {
                ...creatorSearchFilterList,
                page: nextPage,
                limit: paginationLimit,
            };
            setCreatorSearchFilterList(newFilterSet);

            const creators = await getCreatorsFullData(newFilterSet);
            if (creators.data.length !== 0) {
                currentLoadedPage = currentLoadedPage + 1;
            } else {
                allCreatorsLoaded = true;
            }
            setCreatorList([...creatorList, ...creators.data]);
        }
    };

    const isBottomVisible = useIntersectionObserver(ref, { threshold: 0 }, false);

    const handleApplyNewFilterClick = async () => {
        await newCreatorListLoad();
    };

    const setIrrelevanceFilter = (relevanceSelected) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            irrelevant: relevanceSelected,
        });
    };

    const setUtfIssueFilter = (utfIssueSelected) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            utfIssue: utfIssueSelected,
        });
    };

    const setDuplicateFilter = (duplicateSelected) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            duplicate: duplicateSelected,
        });
    };

    const setMinMaxPair = (minMaxPair) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            minFer: minMaxPair[0],
            maxFer: minMaxPair[1],
        });
    };

    const setFreeTextSearch = (phrase) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            freetextSearch: phrase,
        });
    };

    const setCountry = (country) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            country: country,
        });
    };
    const setLabel = (label) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            label: label,
        });
    };

    const setIsBusiness = (isBusiness) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            isBusiness: isBusiness,
        });
    };
    const setHasPublicEmail = (hasPublicEmail) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            hasPublicEmail: hasPublicEmail,
        });
    };
    const setHasWebsite = (hasWebsite) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            hasWebsite: hasWebsite,
        });
    };
    const setMinFing = (minFing) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            minFing: minFing,
        });
    };
    const setMaxFing = (maxFing) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            maxFing: maxFing,
        });
    };
    const setRegistrated = (registrated) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            registrated: registrated,
        });
    };

    const setSort = (decreasing) => {
        setCreatorSearchFilterList({
            ...creatorSearchFilterList,
            sortby: decreasing ? 'decreasing' : 'increasing',
        });
    };

    const handleSortClick = () => {
        setSort(!sortDecreasing);
        setSortDecreasing(!sortDecreasing);
    };

    useEffect(() => {
        if (creatorList !== null) {
            setIsLoadingCreators(false);
        }
    }, [creatorList]);

    useEffect(() => {
        if (isBottomVisible && creatorList !== null && creatorList.length > 0) {
            appendCreatorList();
        }
    }, [isBottomVisible]);

    useEffect(() => {
        newCreatorListLoad();
    }, []);

    return (
        <Fragment>
            <Stack
                justifyContent="flex-end"
                sx={{
                    position: 'sticky',
                    // borderColor: (theme)=>theme.palette.background.main,
                    // borderWidth: '1px',
                    // borderStyle: 'solid',
                    zIndex: (theme) => theme.SectionTools.zIndex,
                    top: (theme) => theme.NavBar.height,
                    left: (theme) => theme.SideBar.width,
                    // width: (theme) => theme.SectionTools.width,
                    minHeight: '125px',
                    // height: (theme) => theme.SectionTools.height,
                    paddingBottom: '0px',
                    paddingRight: (theme) => theme.SectionTools.paddingRight,
                    paddingLeft: (theme) => theme.SectionTools.paddingLeft,
                    bgcolor: (theme) => theme.palette.background.main,
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Stack direction="row">
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',

                                // justifyContent: 'space-between',
                                p: 1,
                                m: 1,
                            }}
                        >
                            <SearchTextField
                                setKeyword={setFreeTextSearch}
                                enterClick={handleApplyNewFilterClick}
                            />

                            <BetterRangeSlider setMinMax={setMinMaxPair} />
                            <SortButton
                                sortDecreasing={sortDecreasing}
                                handleSortClick={handleSortClick}
                            />
                            <UglyLabelSelector
                                handleApplyNewFilterClick={handleApplyNewFilterClick}
                                setLabel={setLabel}
                            />
                            <UglyRegionSelector
                                handleApplyNewFilterClick={handleApplyNewFilterClick}
                                setCountry={setCountry}
                            />
                            <CheckboxButton
                                buttonText="Irrelevance"
                                buttonStatus={irrelevanceState}
                                handlerFunction={handleRelevanceButtonClick}
                            />
                            <CheckboxButton
                                buttonText="Utf Issue"
                                buttonStatus={utfIssueState}
                                handlerFunction={handleUtfIssueButtonClick}
                            />
                            <CheckboxButton
                                buttonText="Duplicate"
                                buttonStatus={duplicateState}
                                handlerFunction={handleDuplicateButtonClick}
                            />
                        </Box>
                        <Box sx={{ minWidth: '70px' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleApplyNewFilterClick}
                                sx={{
                                    position: 'absolute',

                                    right: (theme) => theme.SectionTools.paddingRight,
                                    bottom: '50px',
                                    borderRadius: '50%',
                                    minWidth: '70px',
                                    maxWidth: '70px',
                                    minHeight: '70px',
                                    maxHeight: '70px',
                                    padding: '0px',
                                }}
                            >
                                <SearchIcon />
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </Stack>

            <Grid
                paddingTop="5px"
                container
                spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                columns={{ xs: 12, sm: 12, md: 18, lg: 20, xl: 20 }}
            >
                {!isLoadingCreators && (
                    <CreatorList
                        updateCreatorConcealData={updateCreatorIrrelevanceData}
                        updateCreatorUtfIssueData={updateCreatorUtfIssueData}
                        updateDuplicateData={updateDuplicateData}
                        creatorList={creatorList}
                        isInDashBoard={isInDashBoard}
                    />
                )}
            </Grid>
            <div hidden={isLoadingCreators} ref={ref} style={{ width: '100%', height: '20px' }} />
        </Fragment>
    );
};

export default CreatorCards;
