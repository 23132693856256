import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import {
    Button,
    Card,
    CardContent,
    Dialog,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material/';
import { Close, CopyAll, CheckCircle, ShoppingBagOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';
import {
    getUserCreatorMissions,
    claimMissionDiscountCode,
    sendCreatorMissionPostUrl,
    applyToDeals,
    getApplicationDeals,
} from '../../services/apiCreator';

import WaitLoading from '../../components/WaitLoading';
import AppFrame from '../../hoc/AppFrame';

const VIKWINE_US_SHOP_URL = 'https://digitawine.com/collections';

const DescriptionBasicCreator = () => {
    useAuthorization([roles.BASIC_CREATOR_USER]);

    const [missionData, setMissionData] = useState(null);
    // loader state for mission data
    const [missionDataRequestCompleted, setMissionDataRequestCompleted] = useState(false);
    const [missionDialogueIsOpen, setMissionDialogueIsOpen] = useState(false);

    const onDiscountClaim = () => {
        setMissionDialogueIsOpen(true);
    };

    const handleDialogOpenClose = () => {
        setMissionDialogueIsOpen(!missionDialogueIsOpen);
    };

    const fetchMissionData = async () => {
        const response = await getUserCreatorMissions();
        //const response = {};

        if (response && response.data && response.data.length > 0) {
            const { creatorUser, discountCode, status, postUrl } = response.data[0];
            setMissionData({
                creatorUser: creatorUser,
                discountCode: discountCode,
                status: status,
                postUrl: postUrl,
            });
        }
        setMissionDataRequestCompleted(true);
    };

    useEffect(() => {
        fetchMissionData();
    }, []);

    return (
        <Stack>
            <Typography variant="h2" paddingLeft={2} paddingBottom={2} paddingTop={10}>
                Deals
            </Typography>
            {missionDataRequestCompleted && (
                <Grid container justifyContent="left" spacing={2}>
                    <Grid item xs={12} md={12}>
                        {missionData && missionData.status === 'FULFILLED' && <AplicationCard />}
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {missionData ? (
                            <MissionCard
                                missionData={missionData}
                                setMissionDialogueIsOpen={setMissionDialogueIsOpen}
                            />
                        ) : (
                            <DefaultCard onClick={onDiscountClaim} />
                        )}
                        <MobileMissionDialogue
                            missionDialogueIsOpen={missionDialogueIsOpen}
                            missionData={missionData}
                            handleDialogOpenClose={handleDialogOpenClose}
                            setMissionData={setMissionData}
                        />
                    </Grid>
                </Grid>
            )}
        </Stack>
    );
};

const MissionCard = ({ missionData, setMissionDialogueIsOpen }) => {
    return (
        <Card>
            <CardContent
                sx={{
                    position: 'relative',
                }}
            >
                <Button
                    onClick={() => {
                        setMissionDialogueIsOpen(true);
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                    }}
                >
                    Open
                </Button>
                {missionData.status === 'FULFILLED' && (
                    <CheckCircle
                        sx={{
                            position: 'absolute',
                            right: 12,
                            bottom: 12,
                            color: 'green',
                        }}
                    />
                )}
                <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                    paddingBottom={2}
                >
                    VIK wine Deal
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Discount Code:
                </Typography>
                <Stack direction="row" spacing={1} paddingBottom={1}>
                    <IconButton
                        aria-label="copy"
                        onClick={() => {
                            navigator.clipboard.writeText(missionData.discountCode);
                        }}
                    >
                        <CopyAll />
                    </IconButton>
                    <Typography variant="body" component="div" paddingTop={1}>
                        {`${missionData.discountCode}`}
                    </Typography>
                </Stack>
                {missionData.status === 'OFFER' && (
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Enjoy a 25% discount on any VIK wines selection and publish an instagram
                        post about your experience with VIK wines.
                    </Typography>
                )}
                {missionData.status === 'FULFILLED' && (
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Thanks for sharing your post. This is the beginning of a promising
                        collaboration with VIK wines.
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

const DefaultCard = ({ onClick }) => {
    return (
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                    Vik Wine Deal
                </Typography>
                <Typography variant="body" component="div" paddingBottom={2}>
                    Elevate you influencer career with VIK. Tap to claim your discount to get any
                    selection of VIK wines with 25% off.
                </Typography>
                <Button variant="contained" color="primary" onClick={onClick}>
                    Claim Discount
                </Button>
            </CardContent>
        </Card>
    );
};

const AplicationCard = () => {
    const [appliedToDeals, setAppliedToDeals] = useState(false);
    const [loading, setLoading] = useState(true);

    const onDealApply = async () => {
        try {
            const response = await applyToDeals();
            if (response.status === 201) {
                setAppliedToDeals(true);
            }
        } catch (error) {
            console.log('Error applying to deals:', error);
        }
        setLoading(false);
    };

    const fetchApplicationsToDeals = async () => {
        try {
            setLoading(true);
            const response = await getApplicationDeals();
            if (response.status === 200) {
                setAppliedToDeals(true);
            }
        } catch (error) {
            console.log('No deal applications found');
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchApplicationsToDeals();
    }, []);

    return (
        <Card>
            {!appliedToDeals && !loading && (
                <CardContent>
                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                        Apply for more Deals
                    </Typography>
                    <Typography variant="body" component="div" paddingBottom={2}>
                        We will find the best deals with top wineries to help you grow your
                        influencer career.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onDealApply}
                        disabled={loading}
                    >
                        Apply
                    </Button>
                </CardContent>
            )}
            {appliedToDeals && !loading && (
                <CardContent>
                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                        Waiting for next Deals...
                    </Typography>
                    <Typography variant="body" component="div" paddingBottom={2}>
                        We recieved your application. We are searching for the best deals with top
                        wineries to help you grow your influencer career.
                    </Typography>
                </CardContent>
            )}
            {loading && (
                <CardContent>
                    <Typography
                        paddingBottom={14}
                        sx={{ fontSize: 20 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Finding new Deals...
                    </Typography>
                </CardContent>
            )}
        </Card>
    );
};

const MobileMissionDialogue = ({
    missionDialogueIsOpen,
    missionData,
    handleDialogOpenClose,
    setMissionData,
}) => {
    const [discountCode, setDiscountCode] = useState(false);
    const [discountCodeRequestCompleted, setDiscountCodeRequestCompleted] = useState(false);

    useEffect(() => {
        const fetchDiscountCode = async () => {
            try {
                const response = await claimMissionDiscountCode();

                setDiscountCode(response.data.discountCode);

                setMissionData({
                    discountCode: response.data.discountCode,
                    status: response.data.status,
                    shopUrl: response.data.shopUrl,
                });

                setDiscountCodeRequestCompleted(true);
            } catch (error) {
                console.log('Error claiming discount code');
                if (error.request.status === 423) {
                    console.log('No more discount codes available at the moment');
                }
                console.log(error);
                setDiscountCodeRequestCompleted(true);
            }
        };
        if (missionDialogueIsOpen && missionData) {
            setDiscountCodeRequestCompleted(true);
        }
        if (missionDialogueIsOpen && !missionData) {
            fetchDiscountCode();
        }
    }, [missionDialogueIsOpen]);

    return (
        <Dialog
            open={missionDialogueIsOpen}
            onClose={handleDialogOpenClose}
            sx={{
                zIndex: 5000,
            }}
        >
            <Stack padding={2}>
                <IconButton
                    aria-label="close"
                    onClick={handleDialogOpenClose}
                    sx={{
                        //maximum zIndex
                        zIndex: 9000,
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid',
                    }}
                >
                    <Close />
                </IconButton>
                <Typography variant="h3" paddingRight={5} paddingBottom={6}>
                    VIK Wine Deal
                </Typography>

                <WaitLoading isLoading={!discountCodeRequestCompleted} />
                {discountCodeRequestCompleted && !missionData && (
                    <Typography variant="h4">
                        No more discount codes available at the moment. Stay tuned for new deals.
                    </Typography>
                )}

                {discountCodeRequestCompleted && missionData && (
                    <Stack paddingBottom={8}>
                        <Typography
                            variant="h4"
                            paddingRight={5}
                            paddingBottom={2}
                            sx={{ fontSize: 25 }}
                        >
                            Enjoy this 25% discount on any VIK wines selection
                        </Typography>

                        <Stack direction="row" spacing={1} paddingBottom={1}>
                            <Typography variant="body" component="div" paddingTop={1}>
                                {`${missionData.discountCode}`}
                            </Typography>

                            <IconButton
                                aria-label="copy"
                                onClick={() => {
                                    navigator.clipboard.writeText(missionData.discountCode);
                                }}
                            >
                                <CopyAll />
                            </IconButton>
                        </Stack>
                        <a href="https://digitawine.com/collections" target="_blank">
                            <Button variant="contained">
                                Go to shop
                                <Stack direction="row" paddingLeft={1}>
                                    <ShoppingBagOutlined />
                                </Stack>
                            </Button>
                        </a>
                    </Stack>
                )}
                {missionData && missionData.status === 'OFFER' && (
                    <PostUrlForm setMissionData={setMissionData} missionData={missionData} />
                )}
                {missionData && missionData.status === 'FULFILLED' && (
                    <Typography variant="h2">
                        Thanks for sharing your post.
                        <br />
                        <br />
                        <a href={missionData.postUrl} target="_blank">
                            Link to post
                        </a>
                        <br />
                        <br />
                        You can apply to new deals to keep growing your influencer career.
                    </Typography>
                )}
            </Stack>
        </Dialog>
    );
};

const PostUrlForm = ({ missionData, setMissionData }) => {
    const [submittingPostUrl, setSubmittingPostUrl] = useState(false);
    const validationSchema = yup.object({
        postUrl: yup.string().required('The post link is required'),
    });
    const formik = useFormik({
        initialValues: {
            postUrl: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setSubmittingPostUrl(true);

                const response = await sendCreatorMissionPostUrl(values);
                setMissionData({
                    ...missionData,
                    status: response.data.status,
                    postUrl: response.data.postUrl,
                });
            } catch (error) {
                setSubmittingPostUrl(false);
                console.log(error);
            }
        },
    });
    return (
        <Stack>
            <Typography variant="h2">
                Publish an Instagram post about your experience with VIK wines. Mention @vik_wine to
                apply for new deals
            </Typography>
            <Stack direction="row">
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        variant="standard"
                        fullWidth
                        margin="normal"
                        id="postUrl"
                        name="postUrl"
                        label="Share the link of your post"
                        value={formik.values.postUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.postUrl && Boolean(formik.errors.postUrl)}
                        helperText={formik.touched.postUrl && formik.errors.postUrl}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        endIcon={<SendIcon />}
                        sx={{
                            marginTop: '10px',
                        }}
                    >
                        Send
                    </Button>
                </form>
            </Stack>
            <WaitLoading isLoading={submittingPostUrl} />
        </Stack>
    );
};

export default AppFrame(DescriptionBasicCreator);
